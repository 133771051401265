import React from 'react'
import styles from './BlogCard.module.css'
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
    return (
        <Link to={`${props.link}`}>
            <div className={styles.single_blog}>
                <img src={`${props.blogThumbnail}`} alt="blog" />
                <div className={styles.single_blog_text}>
                    <h3>
                        {props.blogTitle}

                    </h3>
                    <p>
                        {props.blogDecscription}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default BlogCard