import React from "react";
import Layout from "../../components/Layout/Layout";
import styles from './PageNotFound.module.css'
import {Link,useNavigate} from 'react-router-dom'
const PageNotFound = () => {
    const navigate = useNavigate();
  return (
    <Layout headerType="opaque">
      <div className={styles.page_not_found}>
        <img src="/images/not-found.jpg" />
        <h2>Damn! Something went wrong.</h2>
        <p>there's seems to have an error displaying this page. please try later</p>
        <button onClick={() => navigate("/")}>Go to Home</button>
      </div>
    </Layout>
  );
};

export default PageNotFound;
