import React from "react";
import styles from "./ExpertCard.module.css";

const ExpertCard = (props) => {
  return (
    <div className={styles.expert_card}>
      <img src={props.ImagePath} alt="Doctor image" />
      <h4>
        {props.ExpertName} <span>{props.ExpertDescription}</span>
      </h4>
      {/* <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p> */}
    </div>
  );
};

export default ExpertCard;
