import React, { useState, useRef } from 'react'
import Banner from '../../components/Banner/Banner'
import EmployeeListCard from '../../components/EmployeeListCard/EmployeeListCard'
import ExpertCard from '../../components/ExpertCard/ExpertCard'
import TestimonialCard from '../../components/TestimonialCard/TestimonialCard'
import styles from "./Home.module.css"
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from 'react-modal';
import "swiper/css";
import "swiper/css/pagination";
import { Keyboard, Autoplay, Pagination } from "swiper";

import PromicesSection from '../../components/PromicesSection/PromicesSection'
import ContactForm from '../../components/ContactForm/ContactForm'
import Layout from '../../components/Layout/Layout'
import ServicesSection from '../../components/ServicesSection/ServicesSection'

const Home = () => {

  const servicesRef = useRef(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: "transparent"
    },
    overlay: {
      position: 'fixed',
      zIndex: 999,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgb(0 0 0 / 75%)'
    },
  };
  const ctaClickHandler = () => {
    servicesRef.current.scrollIntoView({
      behavior: 'smooth'
    })
  }

  return (
    <div>
      <Layout headerType="transparent">
        <Banner ctaClickHandler={ctaClickHandler} />
        <ServicesSection ref={servicesRef} />
        <section className={styles.testimonials}>
          <div className={styles.testimonials_inner}>
            <h2>Our Valuable Customers Say</h2>
            <div className={styles.testimonials__first_row}>
              <Swiper
                centeredSlides={false}
                loop={true}
                pagination={false}
                modules={[Keyboard, Autoplay]}
                breakpoints={{
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
                keyboard={{
                  enabled: true,
                }}
                className="mySwiper"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
              >
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Ameer Ali"
                    UserContent="This test has helped me become more conscious of my health. It has motivated me towards adopting a healthy lifestyle."
                    UserImage="./images/testimonials/ameer.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Prateeksha"
                    UserContent="The Genomepatri test was very helpful, and the genetic counsellors clarified all my doubts very empathically."
                    UserImage="./images/testimonials/prathiksha.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Selvaraj"
                    UserContent="The Genomepatri test has helped me identify myself better and make informed choices about my health and lifestyle."
                    UserImage="./images/testimonials/selvaraj.jpg" />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <TestimonialCard
                    UserName="Shashi Tharoor"
                    UserContent="I found the report hugely impressive and remarkably accurate which focuses on my overall health. I would be happy to recommend your services to anyone interested."
                    UserImage="./images/testimonials/sasi.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Jayesh Ranjan"
                    UserContent="I am very impressed by the report's depth and the simple and balanced way it has been presented."
                    UserImage="./images/testimonials/jayesh.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Jaspreet Bindra"
                    UserContent="The detailed report is what impressed me the most. It was reasonably well presented and simple to understand. It scanned more than 100 conditions. Also, had a great experience at the genetic counselling session."
                    UserImage="./images/testimonials/jaspreet.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Twinkle George"
                    UserContent="I got a clear picture of the nutrients my body lacks despite following a popular diet which I assumed was working well for me. The report had a detailed analysis of the diet I should follow and to my surprise, it included a few favourite foods of mine. "
                    UserImage="./images/testimonials/twinkle.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialCard
                    UserName="Jithin Rajan"
                    UserContent="I took MyFitGene test right before I was planning to hit the gym. My trainer was able to create a personalised fitness regimen based on my physical and nutritional requirements shown in the report. I would 100% recommend this test."
                    UserImage="./images/testimonials/jithin.jpg" />
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </section>
        <section className={styles.why_choose_us}>
          <div className={styles.why_choose_us_inner}>
            <div className={styles.why_choose_us_video}>
              {/* <h2>Founder's Message</h2> */}
              <div className={styles.video_frame}>
                <img src="./images/ceo.jpg" className={styles.ceo_image} alt="CEO image" />
                {/* <span onClick={openModal} className={styles.video_play}>
                  <img src="./images/play-button.svg" alt="" />
                </span> */}
              </div>
              <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Founder video"
              >
                <div className={styles.vido_popup}>
                  <video className={styles.founder_says} controls autoPlay>
                    <source src="https://d3jvka1ybltcyr.cloudfront.net/founders_msg.webm" type="video/webm" />
                    Your browser does not support HTML video.
                  </video>
                </div>
              </Modal>
            </div>
            <div className={styles.why_choose_us_text}>
              <h3>Founder's Promise</h3>
              <h4>“Your journey of personalised  <br />healthcare starts here”</h4>
              <h5>Tiwit.life has vowed to itself</h5>
              <ul>
                <li>To be the flagbearer of predictive and preventive healthcare. </li>
                <li>To be an agent of change when it comes to public health.</li>
                <li>To bring the best care providers under one umbrella. </li>
                <li>Radicalize the future of health care infrastructure and services. </li>
              </ul>
            </div>
          </div>
        </section>
        <PromicesSection />
        <section className={styles.experts}>
          <div className={styles.experts_inner}>
            <h2>Genetic Counsellors</h2>
            <Swiper
              // slidesPerView={4}
              // spaceBetween={50}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              centeredSlides={false}
              loop={true}
              pagination={false}
              // modules={[Pagination]}
              className="mySwiper"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Keyboard, Autoplay]}
              keyboard={{
                enabled: true,
              }}
            >
              <SwiperSlide>
                <ExpertCard
                  ExpertName="Neeraja Reddy Malleda"
                  ExpertDescription="Genetic Counsellors"
                  ImagePath="./images/experts/4.png"
                />
              </SwiperSlide>
              <SwiperSlide>
                <ExpertCard
                  ExpertName="Neyha Zainab Abbas"
                  ExpertDescription="Genetic Counsellors"
                  ImagePath="./images/experts/1.png"
                />
              </SwiperSlide>
              <SwiperSlide>
                <ExpertCard
                  ExpertName="Surya Babu"
                  ExpertDescription="Genetic Counsellors"
                  ImagePath="./images/experts/5.png"
                />
              </SwiperSlide>
              {/* <SwiperSlide>
                <ExpertCard
                  ExpertName="Hephzibah Bolledhu"
                  ExpertDescription="Genetic Counsellors"
                  ImagePath="./images/experts/3.png"
                />
              </SwiperSlide>
              <SwiperSlide>
                <ExpertCard
                  ExpertName="Shaile Bandla"
                  ExpertDescription="Genetic Counsellors"
                  ImagePath="./images/experts/2.png"
                />
              </SwiperSlide> */}
            </Swiper>

          </div>
        </section>
        <section className={styles.awards}>
          <ul>
            <li>
              <span>Outstanding organization award 2022</span>
              <img src='./images/health-award.png' alt="Award logo" />
            </li>
          </ul>
        </section>
        <section className={styles.contact}>
          <div className={styles.contact_inner}>
            <div className={styles.contact_left}>
              <div className={styles.contact_form}>
                <h2>Let's Talk</h2>
                <ContactForm />
              </div>
            </div>
            <div className={styles.contact_right}>
              <div className={styles.employee_list}>
                <div className={styles.employee_list_container}>
                  <EmployeeListCard
                    employeeName="Eason Mathew"
                    employeeDesignation="Project Head"
                    employeeDescription="Feel free to reach out to us to clarify your concerns."
                    employeeImagePath="./images/employees/eason.png"
                  />
                  <EmployeeListCard
                    employeeName="Christina Ashwin"
                    employeeDesignation="Business Development Manager"
                    employeeDescription="In case you have any questions, drop us a message."
                    employeeImagePath="./images/employees/christina.png"
                  />
                  <EmployeeListCard
                    employeeName="Lisa Remedios"
                    employeeDesignation="Digital Marketing Executive"
                    employeeDescription="For further clarifications, connect with us now."
                    employeeImagePath="./images/employees/lisa.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className={styles.news}>
        <div className={styles.news_inner}>
          <h2>
            News.Tiwit
          </h2>
          <div className={styles.news_list}>
            <BlogCard
              blogTitle="Newborn Baby brain development"
              blogDecscription="s simply dummy text of the printing and typesetting industry. Lorem Ipsum has..."
              blogThumbnail="./images/blog-1.jpg.jpg" />
            <BlogCard
              blogTitle="Newborn Baby brain development"
              blogDecscription="s simply dummy text of the printing and typesetting industry. Lorem Ipsum has..."
              blogThumbnail="./images/blog-2.jpg" />
            <BlogCard
              blogTitle="Newborn Baby brain development"
              blogDecscription="s simply dummy text of the printing and typesetting industry. Lorem Ipsum has..."
              blogThumbnail="./images/blog-3.jpg" />
          </div>
        </div>
      </section> */}
      </Layout>
    </div>
  )
}

export default Home