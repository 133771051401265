import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../components/Layout/Layout'
import { useParams } from 'react-router-dom';
import styles from './Product.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Keyboard, Autoplay, Pagination } from "swiper";



const Product = () => {
    return (
        <Layout>
            <div className={styles.wrapper}>
                <div className={styles.product}>
                    <div className={styles.productDetails}>
                        <div className={styles.mainSlider}>
                            <div className={styles.slider}>
                                <Swiper
                                    centeredSlides={false}
                                    loop={true}
                                    pagination={false}
                                    modules={[Keyboard, Autoplay]}
                                    keyboard={{
                                        enabled: true,
                                    }}
                                    className="mySwiper"
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: true,
                                    }}
                                >
                                    <SwiperSlide>
                                        <div className={styles.imgSlider}>
                                            <img src="./images/product-page/products/genomepatri/1.png" alt='slider' />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                            </div>
                        </div>
                        <div className={styles.productInner}>
                            <div className={styles.product_description}>
                                <h1>MyNutriGene</h1>
                                <h6>Seller: Mapmygenome </h6>
                                <div className={styles.price_details}>
                                    <h4>₹6,499.00
                                        <span>MRP <del>₹9,499.00</del></span>
                                    </h4>
                                    <div className='desktop-flex'>
                                        <a href="#" target='_blank' className={styles.btn_primary}>BUY NOW</a>
                                        <a href="#" target='_blank' className={styles.btn_secondary}>GET ADVICE</a>
                                    </div>
                                </div>
                                <span className={styles.tag}>test</span>
                                <p>MyNutriGene is a full genomic analysis that helps you adopt the best nutrition solutions based on your genetic profile.Your nutritional requirements are connected to your DNA's characteristics. There is no other way to know those specific traits other than by doing a genomic analysis. MyNutriGene will give you a fully personalised report about all the nutrients your body actually needs.</p>
                                <p>Yes! Imagine all that you are going to know about your body! How will you know what your body really needs otherwise? MyNutriGene will also give you a full picture of your genetic tendencies in weight management, lifestyle diseases and risks for food sensitivities. Once you are armed with this information, your doctor, dietician or fitness trainer will be able to recommend to you the perfect nutritional solution for your optimum health.</p>
                            </div>
                            <section className={styles.workingSteps}>
                                <h2 className={styles.title}>How it works? <span>(Just 4 Steps)</span></h2>
                                <div className={styles.workingStepsSlider}>
                                    <Swiper
                                        centeredSlides={false}
                                        loop={true}
                                        pagination={false}
                                        modules={[Keyboard, Autoplay]}
                                        breakpoints={{
                                            768: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                        }}
                                        keyboard={{
                                            enabled: true,
                                        }}
                                        className="mySwiper"
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true,
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className={styles.imageCard}>
                                                <img src="./images/product-page/how-it-works/1.svg" alt='Image' />
                                                <div className={styles.imageCardText}>
                                                    <h6>Sample Collection</h6>
                                                    <p>Collect your sample & make sure the cap is securely fastened.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                </div>
                            </section>
                            <section className={styles.videoTestimonials}>
                                <h2 className={styles.title}>Happy customers...</h2>
                                <div className={styles.videoSlider}>
                                    <Swiper
                                        centeredSlides={true}
                                        loop={true}
                                        pagination={false}
                                        modules={[Keyboard, Autoplay]}
                                        keyboard={{
                                            enabled: true,
                                        }}
                                        breakpoints={{
                                            768: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            1024: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },
                                        }}
                                        className="mySwiper"
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true,
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className={styles.singleTestimonial}>
                                                <p>
                                                    The entire process of ordering the kit, receiving the reports and genetic counselling was really smooth. I look forward to getting it for my entire extended family.
                                                </p>

                                                <div className={styles.singleTestimonialFooter}>
                                                    <img src="./images/product-page/reviews/abhey.jpg" alt='user image' />
                                                    <h4>Abhey Singhania<br />
                                                        <h5>Marketing Manager</h5></h4>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className={styles.singleTestimonial}>
                                                <p>
                                                    The entire process of ordering the kit, receiving the reports and genetic counselling was really smooth. I look forward to getting it for my entire extended family.
                                                </p>

                                                <div className={styles.singleTestimonialFooter}>
                                                    <img src="./images/product-page/reviews/abhey.jpg" alt='user image' />
                                                    <h4>Abhey Singhania<br />
                                                        <h5>Marketing Manager</h5></h4>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                </div>
                            </section>
                        </div>
                    </div>
                    <aside className={styles.product_sidebar}>
                        <div className={styles.card}>
                            <h2>What is MyNutriGene ?</h2>
                            <p>
                                This is a fully scientific, DNA-based health test from Mapmygenome. It screens more than seven lakh genetic markers across the human genome to find explanations for your dietary habits. Because genes are the real mastermind behind your body's response to the food you eat every day and its assimilation of nutrients and more.
                            </p>
                            <p>
                                MyNutriGene will interpret your diet pattern and explain to you the whys and hows - which vitamins do you really and how much, why do you crave some foods, does your immediate family have gluten intolerance etc. Wait no more for symptoms to appear or worry over vitamin deficiencies. MyNutriGene Report will right away show you the fastest way to a healthy life!
                            </p>
                        </div>
                        <div className={styles.card}>
                            <h2>Why should I buy this ?</h2>
                            <ul>
                                <li>You get an authentic and scientific report that helps you eat healthy, stay fit and prevent lifestyle diseases.</li>
                                <li>Save your time and money by finding what works best for your body and what does not.</li>
                                <li>Your doctor, dietician or fitness trainer can recommend better solutions based on this report.</li>
                                <li>You only need to take this test once in a lifetime.</li>
                            </ul>
                        </div>
                        <div className={styles.card}>
                            <h2>Get a sample report</h2>
                            <ul>

                                <li>Still not sure what benefits you will get from the report? Take a look at a real MedicaMap analysis report of a real person. See for yourself how comprehensive and beneficial it is!</li>
                            </ul>
                            <a href="#" target="_blank" rel="noreferrer"><button className="btn">Get sample report</button></a>
                        </div>
                    </aside>
                </div>
            </div>
        </Layout>

    )
}

export default Product