import React, { useState } from "react";
import styles from "./header.module.css";
import { Link, useParams, NavLink } from "react-router-dom";
import useScrollDirection from './../../custom-hooks/useScrollDirection/useScrollDirection'

const Header = (props) => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const scrollDirection = useScrollDirection();
  return (
    <>
      <header
        className={scrollDirection === "down" ? `${styles.mainHeader} ${styles.mainHeaderInActive}` : `${styles.mainHeader} ${styles.mainHeaderActive}`}>
        <div className={styles.headerContainer}>
          <Link to="/">
            <a className={styles.logo}>
              <img src="/images/logo.svg" alt="Tiwit logo" />
            </a>
          </Link>
          <nav className={styles.mainNav}>
            <ul>
              <li className={styles.mainMenu}>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? `${styles.mainMenu} ${styles.active}` : `${styles.mainMenu}`
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className={`${styles.mainMenu} ${styles.hasSubMenu}`}>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? `${styles.mainMenu} ${styles.active}` : `${styles.mainMenu}`
                  }

                >
                  About
                </NavLink>
                <div className={styles.submenu}>
                  <Link to="/about">Our Story</Link>
                  <Link to="/team">Team</Link>
                  {/* <Link to="/about">Partners</Link> */}
                </div>
              </li>
              <li className={`${styles.mainMenu} ${styles.hasSubMenu}`}>
                <NavLink
                  to="/services/preventive-genomics"
                  className={({ isActive }) =>
                    isActive ? `${styles.mainMenu} ${styles.active}` : `${styles.mainMenu}`
                  }
                >
                  Services
                </NavLink>
                <div className={styles.submenu}>
                  <Link to="/services/preventive-genomics">Preventive Genomics</Link>
                  <Link to="/services/cord-blood-banking">Cord Blood Banking</Link>
                </div>
              </li>
              <li className={`${styles.mainMenu} ${styles.hasSubMenu}`}>
                <NavLink
                  to="/partnerships/vendors"
                  className={({ isActive }) =>
                    isActive ? `${styles.mainMenu} ${styles.active}` : `${styles.mainMenu}`
                  }

                >
                  Partnerships
                </NavLink>
                <div className={styles.submenu}>
                  <Link to="/partnerships/vendors">Vendors</Link>
                  <Link to="/partnerships/investors">Investors</Link>
                  <Link to="/partnerships/sales">Sales Partners</Link>
                </div>
              </li>

              <li className={styles.mainMenu}>
                <NavLink
                  to="/blogs"
                  className={({ isActive }) =>
                    isActive ? `${styles.mainMenu} ${styles.active}` : `${styles.mainMenu}`
                  }
                >
                  Blogs
                </NavLink>
              </li>
            </ul>
          </nav>
          <div
            className={`${styles.hamburger_lines} ${mobileMenuActive ? `${styles.hamburger_open}` : ""
              }`}
            onClick={() => setMobileMenuActive(!mobileMenuActive)}
          >
            <span className={`${styles.line} ${styles.line1} `}></span>
            <span className={`${styles.line} ${styles.line2} `}></span>
            <span className={`${styles.line} ${styles.line3} `}></span>
          </div>
        </div>
      </header>
      {mobileMenuActive && (
        <div className={styles.mobile_menu}>
          <div className={styles.mobile_menu_list}>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
              <li>
                <Link to="/partnerships/vendors">Partnerships</Link>
              </li>
              <li>
                <Link to="/services/preventive-genomics">Preventive Genomics</Link>
              </li>
              <li>
                <Link to="/services/cord-blood-banking">Cord Blood Banking</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
            </ul>
          </div>
          <div className={styles.mobile_menu_footer}>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100064899467075"
                >
                  <img src="./images/fb.svg" alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/tiwit.life/">
                  <img src="./images/insta.svg" alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/tiwit.life/">
                  <img src="./images/linkedin.svg" alt="" />
                </a>
              </li>
            </ul>
            <span>© 2023. tiwit.life.</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
