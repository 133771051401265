import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import TeamMember from "../../components/TeamMember/TeamMember";
import styles from "./Team.module.css";
import TeamData from "./../../mockdata/team.json";
import JobList from "./../../mockdata/jobList.json";
import Modal from 'react-modal';
import JobListItem from "../../components/JobListItem/JobListItem";


const Team = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = modalIsOpen ? 'hidden' : 'auto';
  }, [modalIsOpen])

  const detailsClickHandler = (item) => {
    setSelectedJob(item)
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      marginRight: '0',
      border: 'none',
      background: "#fff",
      inset: "0px"
    },
    overlay: {
      position: 'fixed',
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
      zIndex: 999,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgb(0 0 0 / 75%)'
    },
  };
  return (
    <Layout headerType="opaque">
      <div className={styles.team_banner}>
        <h2>
          Team <span>at tiwit</span>
        </h2>
        <p>
          We are a diverse and passionate group of individuals committed to continuous learning and personal growth. Meet the amazing team behind TIWIT Life!
        </p>
      </div>
      <div className={styles.team_inner}>
        {TeamData.map((i) => {
          return (
            <TeamMember
              key={i.id}
              name={i.name}
              title={i.title}
              imagePath={i.imagePath}
              descriptions={i.descriptions}
            />
          );
        })}
      </div>
      <div className={styles.team_banner}>
        <h2>
          Join <span>us</span>
        </h2>
        <p>
          We're always looking for passionate people to join us. No matter what
          you do, If you're excited in what we're building. Write us at{" "}
          <a href="mailto:careers@tiwit.life">careers@tiwit.life</a>
        </p>
      </div>
      <section className={styles.job_listing}>
        <h4>Open Roles</h4>
        <div className={styles.job_listing_inner}>
          <ul>
            {JobList.map((item) => {
              return (
                <JobListItem id={item.id} jobTitle={item.jobTitle} jobLocation={item.jobLocation} detailsClickHandler={() => detailsClickHandler(item)} />
              )
            })}
          </ul>
        </div>
      </section>
      <div className={styles.careers_footer}>
        <h3>
          If you think what we're doing make sense & Excited to collaborate
          check our open roles
        </h3>
        <button>Careers@tiwit.life</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Job details"
        ariaHideApp={false}
      >
        <button onClick={closeModal} className={styles.go_back}>
          <img src="https://img.icons8.com/ios-filled/256/long-arrow-left.png" alt="Back arrow" />
        </button>
        {(Object.keys(selectedJob).length) ? <div className={styles.job_details}>
          <h4>{selectedJob.jobTitle} <span>Location : {selectedJob.jobLocation}</span></h4>
          <p>
            {selectedJob.jobDescription}
          </p>
          <h6>Key Responsibilities:</h6>
          <ul>
            {selectedJob.jobResponsibilities.map((i) => <li>{i}</li>)}
          </ul>
          <h6>Qualifications:</h6>
          <ul>
            {selectedJob.jobQualifications.map((i) => <li>{i}</li>)}
          </ul>
          <h5>Please share your resume to <a href="mailto:jobs@tiwit.in">jobs@tiwit.in</a></h5>
          {/* <div className={styles.application_form}>
            <h5>Apply for this role </h5>
            <form action="">
              <div className={styles.form_group}>
                <input placeholder="Name" />
              </div>
              <div className={styles.form_group}>
                <input placeholder="Mobile Number" />
              </div>
              <div className={styles.form_group}>
                <input placeholder="Upload Resume" />
              </div>
              <button type="submit">Apply Now</button>
            </form>
          </div> */}
        </div> : <h3>Select role</h3>}
      </Modal>
    </Layout>
  );
};

export default Team;
