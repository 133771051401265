import React,{ forwardRef } from "react";
import ServiceCard from "../ServiceCard/ServiceCard";
import styles from "./ServicesSection.module.css";

const ServicesSection = forwardRef((props,ref) => {
  return (
    <section className={styles.services}  ref={ref}>
      <div className={styles.services__inner}>
        <div className={styles.services__top}>
          <h3>
            Finally, India gets a gate way to the world's best <br/>
            predictive & preventive healthcare services.
          </h3>
        </div>
        <div className={styles.services__list}>
          <ServiceCard
            title="Preventive Genomics"
            description="Simple gene tests to help you gain comprehensive knowledge on the future of your health and wellness."
            productLink="/services/preventive-genomics"
            imagePath="/images/services/3.png"
            buttonText="View Products"
          />
          <ServiceCard
            title="Cord Blood Banking"
            description="Reliable services to preserve your baby's stem cells, a ready source of countless lifesaving possibilities."
            productLink="/services/cord-blood-banking"
            imagePath="/images/services/2.png"
            buttonText="Know More"
          />
          <ServiceCard
            title="Wearables"
            description="Innovative wearable technologies to support your physical wellness through active monitoring."
            imagePath="/images/services/4.png"
            comingSoon="true"
          />
          <ServiceCard
            title="Mental Wellness"
            description="Certified assistance from licensed experts to keep your mental and emotional well-being in check."
            imagePath="/images/services/1.png"
            comingSoon="true"
          />
        </div>
        <h3>We are here to change your life for good!</h3>
      </div>
    </section>
  );
});

export default ServicesSection;
