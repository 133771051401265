import './App.css';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import { BrowserRouter } from "react-router-dom"
import { Route, Routes } from "react-router-dom"
import Partnership from './pages/Partnership/Partnership';
import BlogListing from './pages/BlogListing/BlogListing';
import BlogDetail from './pages/BlogDetail/BlogDetail';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from './pages/PrivacyPolicy/Terms';
import Team from './pages/Team/Team';
import RefundPolicy from './pages/PrivacyPolicy/RefundPolicy';
import GeneTesting from './pages/GeneTesting/GeneTesting';
import ScrollToTop from './utilities/ScrollTop';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import CordBloodBanking from './pages/CordBloodBanking/CordBloodBanking';
import Product from './pages/Product/Product';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/partnerships/:id" element={<Partnership/>} />
          <Route path="/team" element={<Team/>} />
          <Route path="/services/preventive-genomics" element={<GeneTesting/>} />
          <Route path="/services/cord-blood-banking" element={<CordBloodBanking/>} />
          <Route path="/blogs" element={<BlogListing/>} />
          <Route exact path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/refund-policy" element={<RefundPolicy/>} />
          <Route path="/terms-and-conditions" element={<Terms/>} />
          <Route path="/products" element={<Product/>} />
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
