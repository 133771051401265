import React from 'react'
import styles from './ProductsCard.module.css'

const ProductsCard = (props) => {
  return (
    <div className={styles.product_card}>
    <img src={`${props.thumbnail}`} alt={`${props.name} image`} />
    <div className={styles.product_card_inner}>
      <h3>{props.name}</h3>
      <span>Brand : {props.brand} </span>
      <h5>₹{props.price}</h5>
      <h6>MRP <span>₹{props.oldPrice}</span></h6>
      <div className={styles.product_card_buttons}>
        <a href={`${props.link}`} target="blank" className={styles.transparent_button}>Know More</a>
        <a href={`${props.purchaseLink}`} target="blank" className={styles.buy_button}>Buy Now</a>
      </div>
    </div>
  </div>
  )
}

export default ProductsCard