import React, { useState, useEffect } from "react";
import BlogCard from "../../components/BlogCard/BlogCard";
import Layout from "../../components/Layout/Layout";
import styles from "./BlogListing.module.css";
import axios from "axios";
import bloglist from "./../../mockdata/blogList.json";

const client = axios.create({
    baseURL: "https://blog.tiwit.life/wp-json/wp/v2/posts?",
});

const BlogListing = () => {
    const [posts, setPosts] = useState(bloglist);
    // useEffect(() => {
    //     client.get('_embed&per_page=20').then((response) => {
    //         setPosts(response.data);
    //     });
    // }, []);

    return (
        <Layout headerType="opaque">
            <div className={styles.banner}>
                <h1>
                    Tiwit.academy
                    <span>Your learning starts here</span>
                </h1>
            </div>
            <section className={styles.blogs_inner}>
                <h3>Featured Articles</h3>
                <div className={styles.featured_articles}>
                    {posts.featuredArticles.map((post) => {
                        return (
                            <BlogCard
                                blogTitle={post.title}
                                blogDecscription={post.description}
                                link={`${post.link}`}
                                blogThumbnail={`${post.image}`}
                            />
                        );
                    })}
                </div>
                <h3>Recent Blogs</h3>
                <div className={styles.regular_articles}>
                    {posts.blogLists.map((post) => {
                        return (
                            <BlogCard
                                blogTitle={post.title}
                                blogDecscription={post.description}
                                link={`${post.link}`}
                                blogThumbnail={`${post.image}`}
                            />
                        );
                    })}
                </div>
            </section>
        </Layout>
    );
};

export default BlogListing;
