export const accordionData = [
  {
    title: "verified & certified vendors",
    content: `We follow a 7-point vendor verification process and have certified vendors.`,
    imgUrl: `./images/usp/usp-1.png`,
  },
  {
    title: "end to end facilitation",
    content: `We manage your healthcare journey from start to finish, ensuring you receive the best advanced healthcare and support.`,
    imgUrl: `./images/usp/usp-2.png`,
  },
  {
    title: "expert connect",
    content: `We connect you with a broad range of experts on the topics most relevant to you.`,
    imgUrl: `./images/usp/usp-3.png`,
  },
  {
    title: "easy payment options",
    content: `We offer flexible payment terms that perfectly match your budget and cash flow needs.`,
    imgUrl: `./images/usp/usp-4.png`,
  },
  {
    title: "dedicated customer relationship manager",
    content: `Our dedicated relationship managers support you from day one to meet your needs.`,
    imgUrl: `./images/usp/usp-5.png`,
  },
];
