import React, { useState } from "react";
import styles from "./PromicesSection.module.css";

const PromicesSectionMobile = (props) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={styles.accordion_item}>
            <div className={styles.accordion_title} onClick={() => setIsActive(!isActive)}>
                <h4 className={isActive ? styles.accordion_active: styles.accordion_inactive}> 
                    {props.title}
                    {/* verified & certified vendors */}
                </h4>
                <div>{isActive ?
                    <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="20" height="3" rx="1.5" fill="#00AEC5" />
                    </svg>
                    :
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C9.26339 0 8.66679 0.597096 8.66679 1.33321V8.66679H1.33321C0.596608 8.66679 0 9.26388 0 10C0 10.7366 0.597096 11.3332 1.33321 11.3332H8.66679V18.6668C8.66679 19.4034 9.26388 20 10 20C10.7366 20 11.3332 19.4029 11.3332 18.6668V11.3332H18.6668C19.4034 11.3332 20 10.7361 20 10C20 9.26339 19.4029 8.66679 18.6668 8.66679H11.3332V1.33321C11.3332 0.596608 10.7361 0 10 0Z" fill="#7E7E7E" />
                    </svg>
                }</div>
            </div>
            {isActive && (
                <div className={styles.accordion_content}>
                    {/* {props.content} */}
                    <p>{props.content}</p>
                    <img src={`${props.imgUrl}`} alt="" />
                </div>
            )}
        </div>
    );
};

export default PromicesSectionMobile;
