import React from "react";
import styles from "./EmployeeListCard.module.css";

const EmployeeListCard = (props) => {
  return (
    <div className={styles.employee_list_card}>
      <div className={styles.employee_image}>
        <img src={`${props.employeeImagePath}`} alt="" />
      </div>
      <div className={styles.employee_details}>
        <h4>
          {props.employeeName}
          <span>{props.employeeDesignation}</span>
        </h4>
        <p>
          {props.employeeDescription}
        </p>
      </div>
    </div>
  );
};

export default EmployeeListCard;
