import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import styles from "./About.module.css";
import useIntersectionObserver from './../../custom-hooks/useIntersectionObserver/useIntersectionObserver'


const About = () => {
    const missionVisionRef = useRef(null);
    const visionRef = useRef(null);
    const storyRef = useRef(null);
    const teamRef = useRef(null);

    const missionVisionIsIntersecting = useIntersectionObserver(missionVisionRef, { threshold: 1 });
    const storyIsIntersecting = useIntersectionObserver(storyRef, { threshold: .5 });
    const teamIsIntersecting = useIntersectionObserver(teamRef, { threshold: 1 });

    const selectMissionHandler = () => {
        missionVisionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const selectStoryHandler = () => {
        storyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const selectTeamHandler = () => {
        teamRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <Layout headerType="opaque">
            <div className={styles.about}>
                <aside>
                    <h2>
                        Story behind creating
                        <span>healthy happy humans!</span>
                    </h2>
                    <ul>
                        <li
                            className={missionVisionIsIntersecting ? `${styles.select_mission} ${styles.active}` : `${styles.select_mission}`}
                            onClick={selectMissionHandler}
                        >
                            <Link>Mission & Vision</Link>{" "}
                        </li>
                        <li
                            className={storyIsIntersecting ? `${styles.select_story} ${styles.active}` : `${styles.select_story}`}
                            onClick={selectStoryHandler}
                        >
                            <Link>Story of tiwit</Link>{" "}
                        </li>
                        <li
                            className={teamIsIntersecting ? `${styles.select_team} ${styles.active}` : `${styles.select_team}`}
                            onClick={selectTeamHandler}
                        >
                            <Link>Team</Link>{" "}
                        </li>
                        <hr />
                    </ul>
                </aside>
                <div className={styles.about_content}>
                    <div className="mission_vision" ref={missionVisionRef}>
                        <div className="mission">
                            <h3>Our Mission</h3>
                            <h4>We Create Healthy, Happy Humans</h4>
                            <p>
                                At TIWIT Life, our priority is ensuring the accessibility of advanced healthcare services nationwide, through our digital platform. We are here to inspire, educate, and offer life-changing services that actively encourage people to be self-reliant. To make this happen, we have unrolled a collaborative digital initiative with experienced vendors providing state-of-the-art technology, thereby making healthy and happy humans.
                            </p>
                        </div>
                        <div className="vision" ref={visionRef}>
                            <h3>Our Vision</h3>
                            <p>
                                TIWIT envisions being a digital marketplace for healthcare services and products with a 360º customer-centric approach. We are integrating multiple services ranging from preventive and predictive medicine to personalisation of care. We are heading towards making futuristic healthcare services affordable and easy-to-buy for every individual in this country. We are an end-to-end advanced healthcare service provider that personalises care by making informed decisions about your health and well-being. We will continue to innovate by leveraging technology for building better customer experiences.
                            </p>
                        </div>
                    </div>
                    <div className="story" ref={storyRef}>
                        <h3>Story of tiwit</h3>
                        <p>
                            TIWIT was founded in 2015 to explore ways to make advanced healthcare services and products accessible and affordable in India. Our primary focus and research objective were to develop and redesign the existing healthcare landscape by aggregating the services and making them accessible to our customers. This helped us determine the greatest potential for working across the entire spectrum of personalised medicine. In 2018 and for the next two years, after a great deal of research and review, we tested the waters by venturing into the business of stem-cell technologies. Later we also experimented with understanding several DNA-related tests to analyse the evolving advanced healthcare market. Based on our findings, we decided to accelerate the company's growth strategy to become the go-to platform for personalised medicine.</p>
                        <p>

                            The latter years were spent building our core team and identifying and associating with top-level vendors that offered exceptional services in the field of healthcare. Thus, we started our journey in 2021 with like-minded individuals and were able to forge ahead with innovation and cutting-edge technologies that deliver better healthcare outcomes to our customers.</p>
                        <p>

                            Since then, TIWIT has not only helped several customers reduce the chance of delayed diagnosis but has also provided services that actively aid them in treatment with the help of our advanced healthcare solutions. We are on the verge of a revolution in which people are empowered to actively manage their own health.
                        </p>
                    </div>
                    <div className="team" ref={teamRef}>
                        <h3>Team</h3>
                        <p>Our team is made up of diverse group of individuals committed to continuous learning, personal growth, and enjoying life to the fullest.</p>
                        <section className={styles.meet_team}>
                            <Link to="/team">Meet the team</Link>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default About;
