import React, { useState, useEffect } from "react";
import styles from "./ContactForm.module.css";
import axios from "axios";
const FORM_ENDPOINT = "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjIwNTY1MDYzMTA0MzQ1MjY0NTUzMSI_3D_pc"; // TODO - fill on the later step


const ContactForm = () => {
    const initialValues = { full_name: "", mobile_number: "", email_id: "", role: "", company_size: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState();
    const [formSuccess, setFormSuccess] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // If show is false the component will return null and stop here
    // if (!formSuccess) {
    //     return null;
    // }

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.full_name) {
            errors.full_name = "Username is required!";
        }
        if (!values.email_id) {
            errors.email_id = "Email is required!";
        } else if (!regex.test(values.email_id)) {
            errors.email_id = "This is not a valid email format!";
        }
        if (!values.mobile_number) {
            errors.mobile_number = "Mobile number required!";
        }
        // if (!values.role) {
        //     errors.role = "Role is required!";
        // }
        // if (!values.company_size) {
        //     errors.company_size = "company size is required!";
        // }
        return errors;
    };
    const handleFocus = (e) => {
        const { name, value } = e.target;
        // setFormValues({ ...formValues, [name]: value });
        // console.log(name)
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: "" })
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0) {
            console.log(formValues)
            setFormValues(initialValues)
            axios.post(FORM_ENDPOINT, {
                formValues
            }
            )
                .then((response) => {
                    setFormSuccess(true)
                })
        }
    };

    useEffect(() => {
        // when the component is mounted, the alert is displayed for 3 seconds
        setTimeout(() => {
            setFormSuccess(false);
        }, 3000);
      }, [formSuccess]); 

    return (
        <div className={styles.contact_form_card}>
            <form onSubmit={handleSubmit}>
                <div className={`${styles.field_group} ${formErrors.full_name ? `${styles.field_group_error}` : ""}`}>
                    <input
                        onChange={handleChange}
                        type="text"
                        name="full_name"
                        placeholder="Name"
                        value={formValues.full_name}
                        onFocus={handleFocus}
                    />
                    <span>{formErrors.full_name}</span>
                </div>
                <div className={`${styles.field_group}  ${formErrors.email_id ? `${styles.field_group_error}` : ""}`}>
                    <input
                        onChange={handleChange}
                        type="text"
                        name="email_id"
                        placeholder="Email ID"
                        value={formValues.email_id}
                        onFocus={handleFocus}
                    />
                    <span>{formErrors.email_id}</span></div>
                <div className={`${styles.field_group}  ${formErrors.role ? `${styles.field_group_error}` : ""}`}>
                    <input
                        onChange={handleChange}
                        type="text"
                        name="mobile_number"
                        placeholder="Contact number"
                        value={formValues.mobile_number}
                        onFocus={handleFocus}
                    />
                    <span>{formErrors.mobile_number}</span></div>
                <div className={`${styles.field_group}  ${formErrors.role ? `${styles.field_group_error}` : ""}`}>
                    <textarea
                        onChange={handleChange}
                        type="text"
                        name="role"
                        placeholder="Message"
                        value={formValues.role}
                        onFocus={handleFocus}
                    ></textarea>
                    <span>{formErrors.role}</span></div>

                {/* <div className={`${styles.field_group}  ${formErrors.company_size ? `${styles.field_group_error}` : ""}`}>
                    <select name="company_size" id="company_size"
                        onChange={handleChange}
                        value={formValues.company_size}
                        onFocus={handleFocus}
                    >
                        <option value="">Company size</option>
                        <option value="25-100">25-100</option>
                        <option value="100-1000">100-1000</option>
                        <option value="1000+">1000+</option>
                    </select>

                    <span>{formErrors.company_size}</span></div> */}
                <button type="submit">Get free advice</button>
                {formSuccess &&  <h6 className={styles.feedback}>Thanks for reaching out. We will get back soon :)</h6>}
               
            </form>
        </div>
    );
};

export default ContactForm;
