import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__inner}>
                <div className={styles.footer__details}>
                    <Link to="/">
                        <img src="/images/grey-logo.svg" alt="Footer logo" />
                    </Link>
                    <span>Infra House, Hennur Gardens, <br /> Bengaluru, Karnataka 560043</span>
                    <span>support@tiwit.in | contact@tiwit.in</span>
                    <span>+91-8904231010</span>
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/profile.php?id=100064899467075">
                                <img src="/images/icons/footer-fb.svg" alt="FB icon" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/tiwit.life/">
                                <img src="/images/icons/footer-Insta.svg" alt="Insta icon" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.linkedin.com/company/tiwit-life">
                                <img src="/images/icons/footer-linkdin.svg" alt="Linked icon" />
                            </a>
                        </li>
                    </ul>
                </div>

                <div className={styles.footer_nav}>
                    <div className={styles.footer_nav_single}>
                        <h5>Company</h5>
                        <ul>
                            <li> <Link to="/about">About</Link></li>
                            <li> <Link to="/team">Team</Link></li>
                        </ul>
                    </div>
                    <div className={styles.footer_nav_single}>
                        <h5>Legal</h5>
                        <ul>
                            <li> <Link to="/terms-and-conditions">Terms of Service</Link></li>
                            <li> <Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li> <Link to="/refund-policy">Refund Policy</Link></li>
                            {/* <li> <Link>Disclaimers</Link></li> */}
                        </ul>
                    </div>
                    <div className={styles.footer_nav_single}>
                        <h5>Partners</h5>
                        <ul>
                            <li>
                                <Link to="/partnerships/vendors">Partnerships</Link>
                            </li>
                            <li>
                                <Link to="/services/preventive-genomics">Preventive Genomics</Link>
                            </li>
                            <li>
                                <Link to="/services/cord-blood-banking">Cord Blood Banking</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <span>Copyright ©2023 TIWIT Technology Solutions Pvt. Ltd. All rights reserved.</span>
        </footer>
    )
}

export default Footer