import React from "react";
import styles from "./ServiceCard.module.css";
import { Link } from "react-router-dom";

const ServiceCard = (props) => {
  return (
    <Link to={!props.comingSoon && `${props.productLink}`}>
    <div className={styles.services__single}>
      <img
        src={`${props.imagePath}`}
        alt={props.title}
        className={styles.services__image}
      />
      <div className={styles.services__text}>
        <h2>
          {props.title}
        </h2>
        <p>{props.description}</p>
        {!props.comingSoon? <Link to={`${props.productLink}`}>View Products</Link>: <span>Coming Soon</span>}
      </div>
    </div>
    </Link>
  );
};

export default ServiceCard;
