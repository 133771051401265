import React, { useState } from "react";
import styles from "./PromicesSection.module.css";
import PromicesSectionMobile from "./PromicesSectionMobile";
import { accordionData } from "./content";
import { Transition } from 'react-transition-group';
import { useRef } from 'react';


const PromicesSection = () => {
    const [selectedPromise, setSelectedPromise] = useState("1");
    const renderCurrentSelection = () => {
        switch (selectedPromise) {
            case "1":
                return (
                    <SingleSelectedUSP
                        image="usp-1.png"
                        description="We follow a 7-point vendor verification process and have certified vendors."
                    />
                );
            case "2":
                return (
                    <SingleSelectedUSP
                        image="usp-2.png"
                        description="We manage your healthcare journey from start to finish, ensuring you receive the best advanced healthcare and support."
                    />
                );
            case "3":
                return (
                    <SingleSelectedUSP
                        image="usp-3.png"
                        description="We connect you with a broad range of experts on the topics most relevant to you."
                    />
                );
            case "4":
                return (
                    <SingleSelectedUSP
                        image="usp-4.png"
                        description="We offer flexible payment terms that perfectly match your company's cash flow needs"
                    />
                );
            case "5":
                return (
                    <SingleSelectedUSP
                        image="usp-5.png"
                        description="Our dedicated relationship managers support you from day one to meet your needs."
                    />
                );

            default:
                return null;
        }
    };
    return (
        <section className={styles.usp}>
            <div className={styles.usp__inner}>
                <div className={styles.usp__left}>
                    <div className={styles.usp__left_inner}>
                        <h3>
                            We Promise <br /> The Best Possible
                        </h3>
                        <ul>
                            <li
                                className={selectedPromise === "1" && `${styles.active}`}
                                onClick={() => setSelectedPromise("1")}
                            >
                                Verified and Certified Vendors
                                {selectedPromise === "1" && <p>
                                    We follow a 7-point vendor verification process and have certified vendors.
                                </p>}
                            </li>
                            <li
                                onClick={() => setSelectedPromise("2")}
                                className={selectedPromise === "2" && `${styles.active}`}
                            >
                                End-End Facilitation
                                {selectedPromise === "2" &&<p>We manage your healthcare journey from start to finish, ensuring you receive the best advanced healthcare and support.</p>}
                            </li>
                            <li
                                className={selectedPromise === "3" && `${styles.active}`}
                                onClick={() => setSelectedPromise("3")}
                            >
                                Expert Connect
                                {selectedPromise === "3" && <p>We connect you with a broad range of experts on the topics most relevant to you.</p>}
                            </li>
                            <li
                                className={selectedPromise === "4" && `${styles.active}`}
                                onClick={() => setSelectedPromise("4")}
                            >
                                Easy Payment Options
                                {selectedPromise === "4" &&<p>We offer flexible payment terms that perfectly match your budget and cash flow needs.</p>}
                            </li>
                            <li
                                className={selectedPromise === "5" && `${styles.active}`}
                                onClick={() => setSelectedPromise("5")}
                            >
                                Dedicated Customer <br />
                                Relationship Manager
                                {selectedPromise === "5" && <p>Our dedicated relationship managers support you from day one to meet your needs.</p>}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.usp__right}>
                    <div className={styles.usp__right_inner}>
                        {renderCurrentSelection()}
                    </div>
                </div>
            </div>
            <div className={styles.mobile_promices}>
                <h3>We promise the best possible</h3>
                {accordionData.map(({ title, content, imgUrl }) => (
                    <PromicesSectionMobile
                        title={title}
                        imgUrl={imgUrl}
                        content={content}
                    />
                ))}
            </div>
        </section>
    );
};

const SingleSelectedUSP = (props) => {
    return (
        <>
            <img
                src={`./images/usp/${props.image}`}
                alt="verified & certified vendors image"
            />
            {/* <p>{props.description}</p> */}
        </>
    );
};

export default PromicesSection;
