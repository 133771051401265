import React from 'react'
import styles from './TeamMember.module.css'

const TeamMember = (props) => {
    return (
        <div className={styles.team_mate}>
            <img src={`${props.imagePath}`} alt="" />
            <div className={styles.team_mate_content}>
                <h4>{props.name}
                    <span>{props.title}</span>
                </h4>
                {props.descriptions.map((description)=> <p>{description}</p>)}
            </div>
        </div>
    )
}

export default TeamMember