import React from 'react'
import styles from './JobListItem.module.css'

const JobListItem = (props) => {
    return (
        <li className={styles.list_item}>
            <h5>{props.jobTitle}
                <span>Location : {props.jobLocation}</span>
            </h5>
            <button onClick={props.detailsClickHandler}>View Details</button>
        </li>
    )
}

export default JobListItem