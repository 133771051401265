import React from 'react'
import Layout from '../../components/Layout/Layout'
import styles from "./PrivacyPolicy.module.css"


const Terms = () => {
    return (
        <Layout headerType="opaque">
            <div className={styles.inner_banner}>
                <h2>Terms of Service</h2>
            </div>
            <div className={styles.pp_inner}>
                <p><span>Welcome </span><span>to the
                    platform tiwit.life <span>(the “Site”). This Site is
                        exclusively </span>owned by Tiwit Technology Solutions Private Limited
                    (“TIWIT”) having its registered office at No. 133, 4th Floor, Janardhan Towers,
                    Residency Road, Bangalore 560 025 </span></p>
                <p><span>By accessing or using the Site you signify that you have read,
                    understand and agree to be bound by these Terms of Service ("Terms of
                    Service"), regardless of whether you are a registered User of  . If you do
                    not agree to these Terms of Service, then do not use the Site.</span></p>
                <p><span>These Terms of Service are effective as of the date posted above
                    and we will not be bound by any additional or different terms on other
                    documents that are inconsistent with these terms. We may modify or update these
                    Terms of Service at any time without notice by posting the amended terms on the
                    Site and such terms shall be effective for all use of the Site and any services
                    or information offered on the Site, including information about participating
                    in our programs (“Services”) once they are posted. Your continued access of the
                    Site and/or use of the Services provided on the Site following the posting of
                    any additional or different terms in the Terms of Service constitutes your
                    acceptance of those additional or different terms. We, in our sole discretion,
                    may also add, delete or change some or all of the features of the Site or our
                    Services at any time.</span></p>
                <p><span>These Terms of Service govern your access and use of the Site and
                    all content, services and/or functions or activities provided through the Site.
                    Please read these Terms of Service carefully before using the Site. If you
                    violate any of these Terms of Service (which include by reference
                    our&nbsp;Privacy Policy, or otherwise violate an agreement between you and us,
                    we may terminate your registration, delete your profile and any content or
                    information that you have posted on the Site and/or prohibit you from using or
                    accessing the Site (or any portion, aspect or feature of the Site), at any time
                    in its sole discretion, with or without notice.</span></p>
                <p ><span>As
                    used herein, “User” or "Users" means anyone who accesses and/or uses
                    the Site.</span></p>
                <p><b><span>1.</span></b><b><u><span>DESCRIPTION
                    OF THE SERVICES</span></u></b></p>
                <p><span>Subject to the terms and conditions specified herein, the Site
                    offers Users information regarding us and our programs. The Site also offers
                    Users to avail the listed services, the possibility of accessing video content,
                    and obtaining information about the programs, communicating through certain
                    functions provided on the Site or accessing our social media sites.</span></p>
                <p><b><span>2.</span></b><b><u><span>COLLECTION
                    OF PERSONAL DATA</span></u></b></p>
                <p ><span>2.1<span>&nbsp;&nbsp;
                </span></span><span>Certain of
                    our Services, including signing up for updates regarding our programs or
                    participating in certain functions provided by the Site, require Users to
                    provide personal data, as detailed in our Privacy Policy.&nbsp;If you wish to
                    obtain information regarding the Services, we may direct you away from the Site
                    to a third-party site.</span></p>
                <p ><span>2.2<span>&nbsp;&nbsp;
                </span></span><span>You agree
                    by providing personal data to us or through a third-party site: </span></p>

                <p><span>2.2.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>to these
                    Terms of Service; </span></p>
                <p><span>2.2.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>to provide
                    true, accurate, current and complete information; </span></p>
                <p><span>2.2.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>to maintain
                    and promptly update the information to keep it true, accurate, current and
                    complete; </span></p>
                <p><span>2.2.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>for
                    security reasons, to maintain the confidentiality of personal data; </span></p>
                <p><span>2.2.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>to ensure
                    that you properly exit from your account at the end of each session and to
                    immediately notify us of any unauthorized use of your account or any other
                    breach of security; and </span></p>
                <p><span>2.2.6<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>to take
                    full responsibility for all activities that occur under any account created for
                    your use.</span></p>

                <p ><span>2.3<span>&nbsp;&nbsp;
                </span></span><span>We are not
                    responsible for any loss or damage arising from your failure to comply with the
                    foregoing requirements. If you provide any information that is untrue,
                    inaccurate, not current or incomplete, or to which you do not have rights, or
                    if we have reasonable grounds to suspect that such information is untrue,
                    inaccurate, not current or incomplete, we may suspend or terminate your account
                    and refuse any and all current or future use of the Services (or any portion
                    thereof).</span></p>
                <p ><b><span>3.</span></b><b><u><span>ELIGIBILITY</span></u></b></p>

                <p><span>3.1<span>&nbsp;&nbsp;
                </span></span><span>This Site
                    is intended solely for Users who are over the age of </span><span>18<span> and any
                        registration by, use of or access to the Site by anyone who is not over </span>18<span> is unauthorized, unlicensed and in violation of these
                            Terms of Service. By using the Services or the Site, you represent and warrant
                            that you are over </span>18<span> and that you agree to and
                                abide by all of the terms and conditions of these Terms of Service.</span></span></p>

                <p><span>3.2<span>&nbsp;&nbsp;
                </span></span><span>However, keeping in
                    mind the sensitivity of the services offered by TIWIT under the platform tiwit.life
                        <span>It is clarified that, </span>children between the age
                        of 16 to 18 may use the Site only in presence of their natural guardian or
                        Parents. Any use by children below 16 will not attract any consequences or any
                        sort of liability to TIWIT or to the platform tiwit.life</span></p>

                <p><span>3.3<span>&nbsp;&nbsp;
                </span></span><span>If you
                    violate any of these Terms of Service, or otherwise violate an agreement
                    between you and us, or for any other reason, we may terminate your registration
                    and/or prohibit you from using or accessing the Services or the Site (or any
                    portion, aspect or feature of the Services or the Site), at any time in our
                    sole discretion. You agree that we will not be liable to you or any third party
                    for any termination of your account.</span></p>

                <p ><b><span>4.</span></b><b><u><span>CONTENT
                    OF SITE</span></u></b></p>
                <p ><b><span>&nbsp;</span></b></p>
                <p ><span>4.1<span>&nbsp;&nbsp;
                </span></span><span>We are not
                    responsible if information on the Site is not accurate, complete or current.
                    Any reliance on the material on the Site is at your own risk. We shall not be
                    liable to you or to any third party for any modification, suspension or
                    discontinuance of the Service.</span></p>
                <p ><b><span>&nbsp;</span></b></p>
                <p ><span>4.2<span>&nbsp;&nbsp;
                </span></span><span>Information
                    on the Site may contain typographical errors, inaccuracies or omissions. We
                    reserve the right to correct any errors, inaccuracies or omissions, and to
                    change or update information if any on the Site, which is inaccurate at any
                    time without prior notice.</span></p>
                <p><b><span>&nbsp;</span></b></p>
                <p ><span>4.3<span>&nbsp;&nbsp;
                </span></span><span>We
                    undertake no obligation to update, amend or clarify information on the Site,
                    except as required by law. No specified update to the Site should be taken to
                    indicate that all the information in the Site has been modified or updated.</span></p>
                <p><b><span>&nbsp;</span></b></p>
                <p ><a name="_heading=h.gjdgxs" /><b><span>5.</span></b><b><u><span>CONTENT
                    SUBMITTED BY USERS</span></u></b></p>
                <p ><b><span>&nbsp;</span></b></p>
                <p ><b><span>5.1<span>&nbsp;
                </span></span></b><span>With
                    the exception of </span></p>
                <p><b><span>&nbsp;</span></b></p>
                <p><span>5.1.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>content
                    Users post to the Site, directly through the Site or through any Contributor
                    app, or submits through social media links, including any comments or other
                    submissions (“User Content”) and content you submit through the Site or social
                    media links, including any comments or other submissions (“Your Content”), </span></p>
                <p><b><span>&nbsp;</span></b></p>
                <p><span>5.1.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>all
                    materials contained on the Site and provided on the Services, including all
                    content, and the software, graphics, text and look and feel of the Site, and
                    all trademarks, copyrights, patents and other intellectual property rights
                    related thereto (“Proprietary Materials”), are owned or controlled by TIWIT, or
                    any of its subsidiaries or affiliated companies, contributors and/or our third
                    party licensors with all rights reserved. </span></p>
                <p><b><span>&nbsp;</span></b></p>
                <p><span>5.2<span>&nbsp;&nbsp; </span></span><span>You may not modify, remove,
                    delete, augment, add to, publish, download, transmit, participate in the
                    transfer or sale of, create derivative works from, or in any way exploit any
                    Proprietary Materials, or any other protectable aspects of the Site, in whole
                    or in part, unless specifically stated otherwise by us in writing.&nbsp;Such
                    unauthorized use may also violate applicable laws including without limitation
                    copyright and trademark laws and applicable communications regulations and
                    statutes. Unless explicitly stated herein, nothing in these Terms of Service
                    shall be construed as conferring any license to intellectual property rights,
                    whether by estoppel, implication or otherwise.</span></p>

                <p><span>5.3<span>&nbsp;&nbsp; </span></span><span>You acknowledge that TIWIT is
                    an online service provider that may post content provided by Users to the Site,
                    including elected officials submitting video content. Any opinions, advice,
                    statements, judgments, services, offers, or other information that constitutes
                    part of the content expressed or made available by third parties, including
                    User Content (e.g., videos submitted by elected officials) and Your Content,
                    are those of the respective Users and not of TIWIT or its affiliates or any of
                    their officers, directors, employees, or agents. </span></p>

                <p><span>5.4<span>&nbsp;&nbsp; </span></span><span>While we retain the right to
                    filter or reject User Content and other content posted by third parties, we
                    review User Content and other third party content in a limited gatekeeper
                    fashion and have no obligation to investigate whether any User Content violates
                    any term of this Agreement, and will not do so in most cases. We neither
                    endorse nor are responsible for the User Content, Your Content or any other
                    content posted to the Site by anyone.</span></p>

                <p><span>5.5<span>&nbsp;&nbsp; </span></span><span>By providing Your Content, you
                    certify that Your Content consists of original material to which you have all
                    the rights; that Your Content does not violate the rights of any third party,
                    including, without limitation, copyright, trademark, patent, privacy or any
                    rights creating claims for idea misappropriation or the right of publicity;
                    that Your Content does not contain any material that is abusive, vulgar,
                    threatening, harassing, libellous, defamatory, obscene or that is known to be
                    false; that Your Content does not violate any applicable laws; and that Your
                    Content does not include any private or personally identifiable information
                    regarding any third party.</span></p>

                <p><span>5.6<span>&nbsp;&nbsp; </span></span><span>Just as you retain complete
                    ownership of Your Content (subject to the license to us granted above), all
                    other users of the Site retain ownership of their User Content. You may not
                    modify, remove, delete, augment, add to, publish, transmit, participate in the
                    transfer or sale of, create derivative works from, or in any way exploit any
                    User Content completely or in part.</span></p>

                <p><span>5.7<span>&nbsp;&nbsp; </span></span><span>You acknowledge that&nbsp;we
                    are not obligated to use or pay you for Your Content; that we may publish Your
                    Content on the Site and on accounts associated with the Site on third party
                    social media platforms; that we may edit or remove content that we determine in
                    our sole discretion is unlawful, offensive, threatening, libellous, defamatory,
                    pornographic, obscene or otherwise objectionable or violates any party’s
                    intellectual property or these Terms of Services; </span></p>

                <p><span>5.8<span>&nbsp;&nbsp; </span></span><span>That Your Content may be edited
                    for length, clarity and/or functionality; that we may display your first name
                    and first initial of your last name in conjunction with Your Content; that we
                    may restrict comments or distribution of Your Content to certain parts of the
                    Site and to certain users of the Site; and that Your Content may be shared with
                    legal authorities if we believe it is warranted or pursuant to a verified
                    request.</span></p>

                <p ><b><span>6.</span></b><b><u><span>PROHIBITED
                    CONDUCT</span></u></b></p>

                <p><span>6.1<span>&nbsp;&nbsp; </span></span><span>You agree to use the Site and
                    Service only for purposes that are legal, proper and in accordance with these
                    Terms of Service and any applicable law, rules or regulations. You may not use
                    the Service in any manner that could damage, disable, overburden, or impair the
                    Service, or interfere with any other party's use and enjoyment of the Service;</span></p>
                <p><b><u></u></b></p>
                <p><span>6.2<span>&nbsp;&nbsp; </span></span><span>use the account, username, or
                    password of another account holder at any time or disclose your password to any
                    third party or permit any third party to access your account;</span></p>


                <p><span>6.3<span>&nbsp;&nbsp; </span></span><span>attempt to gain unauthorized
                    access to the Site, the Service, or the computer systems or networks connected
                    to the Service through hacking, password mining or any other means;</span></p>
                <p><b><u></u></b></p>
                <p><span>6.4<span>&nbsp;&nbsp; </span></span><span>create User accounts by
                    automated means or under false or fraudulent pretences;</span></p>

                <p><span>6.5<span>&nbsp;&nbsp; </span></span><span>transmit any viruses, worms,
                    defects, Trojan horses, or any items of a destructive nature;</span></p>

                <p><span>6.6<span>&nbsp;&nbsp; </span></span><span>defame, abuse, harass, stalk,
                    threaten or otherwise violate the legal rights (such as rights of privacy and
                    publicity) of others;</span></p>

                <p><span>6.7<span>&nbsp;&nbsp; </span></span><span>upload, post, email or
                    transmit, or otherwise make available through the Service any inappropriate,
                    defamatory, infringing, obscene, or unlawful content;</span></p>

                <p><span>6.8<span>&nbsp;&nbsp; </span></span><span>depict, encourage or partake in
                    criminal or tortious activity, including fraud, trafficking in obscene or
                    illegal material, drug dealing or using, underage drinking, gambling, violence,
                    harassment, stalking or spamming;</span></p>

                <p><span>6.9<span>&nbsp;&nbsp; </span></span><span>upload, post, email or
                    transmit, or otherwise make available through the Service any content that
                    infringes any patent, trademark, copyright, trade secret or other proprietary
                    right of any party, unless you are the owner of such rights or have the
                    permission of the owner to post such content;</span></p>

                <p><span>6.10<span>&nbsp;&nbsp; </span></span><span>upload, post, email or
                    transmit, or otherwise make available through the Service any materials that
                    promote pyramid schemes, chain letters or disruptive commercial messages or
                    advertisements, or anything else prohibited by law;</span></p>

                <p><span>6.11<span>&nbsp;&nbsp; </span></span><span>run Mail list, Listserv, or any
                    form of auto-responder or "spam" on the Service;</span></p>

                <p><span>6.12<span>&nbsp;&nbsp; </span></span><span>use manual or automated
                    software, devices, or other processes to "crawl" or
                    "spider" any page of the Site, including to engage in the practices
                    of "screen scraping," "database scraping" or any other
                    activity with the purpose of obtaining content or other information;</span></p>

                <p><span>6.13<span>&nbsp;&nbsp; </span></span><span>interfere or attempt to
                    interfere with the proper working of the Service or any activities conducted on
                    the Service, including to utilize framing techniques to enclose any Content or
                    other proprietary information, place pop-up windows over the Site's pages, or
                    otherwise affect the display of the Site's pages;</span></p>
                <p><b><u></u></b></p>
                <p><span>6.14<span>&nbsp;&nbsp; </span></span><span>download any file posted by
                    another User that you know, or reasonably should know, cannot be legally
                    distributed in such manner;</span></p>

                <p><span>6.15<span>&nbsp;&nbsp; </span></span><span>impersonate another person or entity,
                    or falsify or delete any author attributions, legal or other proper notices or
                    proprietary designations or labels of the origin or source of any materials;</span></p>

                <p><span>6.16<span>&nbsp;&nbsp; </span></span><span>remove any copyright, trademark
                    or other proprietary rights notices contained in or on the Service;</span></p>

                <p><span>6.17<span>&nbsp;&nbsp; </span></span><span>use any robot, spider, site
                    search/retrieval application, or other device to retrieve or index any portion
                    of the Service or collect information about its Users for any unauthorized
                    purpose;</span></p>

                <p><span>6.18<span>&nbsp;&nbsp; </span></span><span>submit content that falsely
                    expresses or implies that such content is sponsored or endorsed by us, any of
                    its affiliates or any third parties;</span></p>

                <p><span>6.19<span>&nbsp;&nbsp; </span></span><span>use the Service for any illegal
                    or unauthorized purpose (including, without limitation, in violation of any
                    Indian Central or State laws or regulations, or equivalent laws or regulations
                    in foreign jurisdictions);</span></p>

                <p><span>6.20<span>&nbsp;&nbsp; </span></span><span>promote or provide
                    instructional information about illegal activities or promote physical harm or
                    injury against any group or individual;</span></p>

                <p><span>6.21<span>&nbsp;&nbsp; </span></span><span>upload illegal content,
                    including, without limitation, child pornography;</span></p>

                <p><span>6.22<span>&nbsp;&nbsp; </span></span><span>use the Site or the Services to
                    threaten the elected officials using the messaging features of the Site;</span></p>

                <p><span>6.23<span>&nbsp;&nbsp; </span></span><span>share or disclose with anyone
                    any information obtained through the Service about any investment offerings; or</span></p>

                <p><span>6.24<span>&nbsp;&nbsp; </span></span><span>use the Service for any
                    commercial purpose whatsoever other than for your personal use.</span></p>

                <p ><b><span>7.</span></b><b><u><span>COPYRIGHT</span></u></b></p>
                <p><span>7.1<span>&nbsp;&nbsp; </span></span><span>If you believe that any
                    content, including User Content or other materials, posted on the Site
                    constitutes an infringement of your copyrights, we will respond promptly to any
                    properly submitted notice containing the information detailed below. Written
                    notifications of claimed copyright infringement should be sent to our
                    designated agent at the following contact information:&nbsp;support@tiwit.in</span></p>

                <p><span>7.2<span>&nbsp;&nbsp; </span></span><span>To be effective, the
                    notification must be a written communication that includes the following:</span></p>

                <p><span>7.2.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>A physical
                    or electronic signature of person authorized to act on behalf of the owner of
                    an exclusive right that is allegedly infringed;</span></p>

                <p><span>7.2.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>Identification
                    of the copyrighted work claimed to have been infringed, or if multiple
                    copyrighted works at a single online site are covered by a single notification,
                    a representative list of such works at that site;</span></p>

                <p><span>7.2.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>Identification
                    of the material that is claimed to be infringing or to be the subject of
                    infringing activity and that is to be removed or access to which is to be
                    disabled, and information reasonably sufficient to permit us to locate the
                    material;</span></p>

                <p><span>7.2.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>Information
                    reasonably sufficient to permit us to contact the complaining party, such as an
                    address, telephone number, and if available, an electronic mail address at
                    which the complaining party may be contacted;</span></p>

                <p><span>7.2.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>A statement
                    that the complaining party has a good faith belief that use of the material in
                    the manner complained of is not authorized by the copyright owner, its agent,
                    or the law; and</span></p>

                <p><span>7.2.6<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>A statement
                    that the information in the notification is accurate, and under penalty of
                    perjury, that the complaining party is authorized to act on behalf of the owner
                    of an exclusive right that is allegedly infringed.</span></p>

                <p ><b><span>8.</span></b><b><u><span>LINKED
                    SITES</span></u></b></p>
                <p ><span>8.1<span>&nbsp;&nbsp;
                </span></span><span>The Site
                    may contain links to other Internet sites, applications and resources including
                    embedded content, sponsored content or co-branded content. Links found on the
                    Site may let Users leave the Site and go to sites operated by parties other
                    than us. We do not endorse, and are not responsible or liable in any way for,
                    any content, advertising, services or goods on or available from such sites,
                    applications or resources. </span></p>

                <p><span>8.2<span>&nbsp;&nbsp;
                </span></span><span>The linked
                    sites are not under the control of us and we are not responsible for the contents
                    of any linked site or any link contained in a linked site that is not owned and
                    operated by us, or any changes or updates to such sites. Such links to sites
                    maintained by third parties do not constitute an endorsement, guaranty, or
                    warranty by us, or any of our affiliates, of any third party or their content,
                    nor the existence of a partnership, joint venture, agency, or other
                    relationship between us, or any of our affiliates, and any linked third party
                    or their content. </span></p>

                <p><span>8.3<span>&nbsp;&nbsp;
                </span></span><span>We do not
                    assume any responsibility or liability for the actions, products, content
                    and/or information of these and other third parties and/or their sites. Third
                    parties’ sites are not subject to our Terms of Service and Privacy Policy. You
                    should carefully review their privacy statements and other conditions of use.</span></p>

                <p ><b><span>9.</span></b><b><u><span>DISCLAIMERS</span></u></b></p>
                <p><span>9.1<span>&nbsp;&nbsp;
                </span></span><span>You
                    understand that the processing and transmission of communications relating to
                    the use of the Site or the Services, including your data, may involve
                    transmissions over various networks and changes to conform and adapt to
                    technical requirements of connecting to various networks or devices. Therefore,
                    you agree that we will not be liable for the timeliness, deletion, mis-delivery
                    or failure to store any data, information or content transmitted by you through
                    the Site.</span></p>

                <p><span>9.2<span>&nbsp;&nbsp;
                </span></span><span>WE PROVIDE
                    THE SITE AND THE SERVICES ON AN "AS IS" AND "AS AVAILABLE"
                    BASIS WITHOUT ANY WARRANTY OR CONDITION, EXPRESS OR IMPLIED, TO THE FULL EXTENT
                    PERMISSIBLE UNDER APPLICABLE LAW, WE SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES
                    INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</span></p>

                <p><span>9.3<span>&nbsp;&nbsp;
                </span></span><span>We make no
                    representation or warranty that </span></p>

                <p><span>9.3.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>the Site or
                    the Services will meet your expectations or requirements, </span></p>
                <p><span>9.3.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>the Site or
                    the Services will be uninterrupted, timely, accurate, secure, complete or
                    error-free, </span></p>
                <p><span>9.3.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>any results
                    or information that may be obtained from the use of the Site or the Services
                    will be accurate, timely, complete or reliable and </span></p>
                <p><span>9.3.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span>any errors
                    in any software used on the Site or in connection with the Services will be
                    corrected. </span></p>

                <p><span>9.4<span>&nbsp;&nbsp;
                </span></span><span>Operation
                    of the Site may be interfered with by numerous factors outside of our control
                    including, but not limited to, telecommunications network disruptions. </span></p>

                <p><span>9.5<span>&nbsp;&nbsp;
                </span></span><span>We are not
                    responsible and will have no liability for any failures of the Internet or any
                    data or telecommunications equipment, system or network used in connection with
                    the Site or the Services.</span></p>

                <p><span>9.6<span>&nbsp;&nbsp;
                </span></span><span>While we
                    have endeavored to create a secure and reliable Site, we are not responsible
                    for the security of information transmitted via the Internet, the accuracy of
                    the information contained on the Site, or for the consequences of any reliance
                    on such information. You must make your own determination as to these matters. </span></p>

                <p><span>9.7<span>&nbsp;&nbsp;
                </span></span><span>We and our
                    affiliates shall not be liable for damages as a result of any delay or other
                    failure of performance due to causes beyond our reasonable control including,
                    without limitation, acts of God, acts of customer or any of its
                    representatives, acts of military or civil authorities, fire or other casualty,
                    strikes, lockouts, weather, epidemic, war, riot, terrorism, telecommunications
                    interruptions or computer viruses. The Site may be temporarily unavailable from
                    time to time for maintenance or other reasons.</span></p>

                <p ><b><span>10.<span>&nbsp;
                </span></span></b><b><u><span>LIMITATION
                    ON LIABILITY</span></u></b></p>
                <p ><b><span>&nbsp;</span></b></p>
                <p><span>10.1<span>&nbsp;&nbsp;
                </span></span><span>IN NO EVENT
                    SHALL WE, OUR AFFILIATES OR OUR LICENSORS, TOGETHER WITH EACH OF OUR RESPECTIVE
                    EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND SHAREHOLDERS, BE LIABLE WITH RESPECT
                    TO ANY SUBJECT MATTER OF THIS AGREEMENT (HOWEVER ARISING, INCLUDING
                    NEGLIGENCE), OR </span></p>
                <p><span><br />
                    <br />
                </span></p>

                <p><span>10.1.1</span><span>ANY LOST OR
                    CORRUPTED DATA, LOST PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT OR
                    CONSEQUENTIAL DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES, OR </span></p>

                <p><span>10.1.2</span><span>THE COST OF
                    PROCURING SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY. SOME JURISDICTIONS MAY NOT
                    ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
                    THE ABOVE EXCLUSIONS SHALL ONLY APPLY TO THE EXTENT PERMISSIBLE UNDER
                    APPLICABLE LAW.</span></p>

                <p><span>10.2<span>&nbsp;&nbsp;
                </span></span><span>TO THE FULL
                    EXTENT PERMISSIBLE UNDER APPLICABLE LAW, OUR AGGREGATE LIABILITY TO YOU OR ANY
                    THIRD PARTY IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF </span></p>

                <p><span>10.2.1</span><span>THE
                    AGGREGATE OF THE FEES PAID BY YOU OR US FOR THE SERVICES DURING THE TWELVE (12)
                    MONTH PERIOD IMMEDIATELY PRECEDING THE DATE THAT ANY CLAIM ARISES, </span></p>

                <p><span>10.2.2</span><span>OR (B)
                    Rs.10000.</span></p>


                <p ><b><span>11.<span>&nbsp;
                </span></span></b><b><u><span>INDEMNITY</span></u></b></p>
                <p><span>11.1<span>&nbsp;&nbsp;
                </span></span><span>You agree
                    to defend, indemnify and hold us and our affiliates, together with each of our
                    respective employees, agents, directors, officers and shareholders, harmless
                    from and against all liabilities, losses, claims, damages, costs and expenses
                    (including reasonable attorneys' fees and costs) arising out of </span></p>
                <p><span>11.1.1</span><span>your use or
                    misuse of the Site or the Services, </span></p>
                <p><span>11.1.2</span><span>your breach
                    or alleged breach of these Terms of Service, and </span></p>
                <p><span>11.1.3</span><span>your
                    violation of any law, rule, regulation or rights of others in connection with
                    your use of the Site or the Services.</span></p>

                <p ><b><span>12.<span>&nbsp;
                </span></span></b><b><u><span>TERMINATION</span></u></b></p>
                <p><span>12.1<span>&nbsp;&nbsp;
                </span></span><span>You agree
                    that we, in our sole discretion and without prior notice or liability to you,
                    may issue a warning, temporarily suspend, indefinitely suspend, or terminate
                    your account, at our sole discretion, for any reason, whether with or without
                    cause or warning, and without liability. </span></p>
                <p><span>12.2<span>&nbsp;&nbsp;
                </span></span><span>In the
                    event of termination of your account, we may delete and/or store, in our
                    discretion, data associated with your use of the Site. In the event of
                    termination, we have no further obligations to you.</span></p>


                <p ><b><span>13.<span>&nbsp;
                </span></span></b><b><u><span>MISCELLANEOUS</span></u></b></p>
                <p ><u></u></p>
                <p><span>13.1<span>&nbsp;&nbsp;
                </span></span><span>This
                    Agreement constitutes the entire agreement between you and us with respect to
                    your access and use of the Site and the Services and supersedes all prior and
                    contemporaneous agreements between you and us. </span></p>

                <p><span>13.2<span>&nbsp;&nbsp;
                </span></span><span>If any of
                    the provisions of this Agreement is found by a court of competent jurisdiction
                    to be invalid or unenforceable, then that provision will be construed in a
                    manner consistent with applicable laws to reflect, as nearly as possible, the
                    original intentions of the parties, and the remaining provisions shall remain
                    in full force and effect. </span></p>

                <p><span>13.3<span>&nbsp;&nbsp;
                </span></span><span>The failure
                    of us to exercise or enforce any right or provision of this Agreement shall not
                    constitute a waiver of such right or provision. The provisions of Sections 3-6,
                    and 9-14 shall survive any termination of these Terms of Service.</span></p>

                <p><span>13.4<span>&nbsp;&nbsp;
                </span></span><span>This Site
                    is targeted at and intended for visitors residing in India. Given the global
                    nature of the Internet, however, the Site may be accessed by visitors residing
                    outside of India. We make no representations or warranties that the Site is
                    appropriate or available for use in countries outside of India. Visitors who
                    choose to access the Site from outside of India do so at their own initiative
                    and are not responsible for compliance with any and all local laws and
                    regulations that may apply to such access.</span></p>

                <p><span>13.5<span>&nbsp;&nbsp;
                </span></span><span>The laws
                    applicable to the interpretation of this Agreement shall be the laws of Central
                    Government as may be amended or modified by the State of Karnataka, India. </span></p>

                <p><span>13.6<span>&nbsp;&nbsp;
                </span></span><span>You agree
                    to submit to the personal and exclusive jurisdiction of the state courts
                    located within Bengaluru, Karnataka. You also agree that, in the event any
                    dispute or claim arises out of or relating to your use of the Site or the
                    Services, that you and we will attempt in good faith to negotiate a written
                    resolution of the matter directly between the parties. You agree that if the
                    matter remains unsettled for forty-five (45) days after notification (via
                    certified mail or personal delivery) that a dispute exists, all parties shall
                    join in mediation services in Bengaluru, Karnataka with a mutually agreed
                    mediator to settle the dispute. Should you file any arbitration claims,
                    administrative or legal actions without first having attempted to resolve the
                    matter by mediation, then you will not be entitled to recover attorney's fees,
                    even if you would otherwise be entitled to them.</span></p>
                <p><b><span>&nbsp;</span></b></p>
                <p ><b><span>14.<span>&nbsp;
                </span></span></b><b><u><span>ASSIGNMENT.</span></u></b></p>
                <p><span>14.1<span>&nbsp;&nbsp;
                </span></span><span>We may
                    assign these Terms of Service at any time to a subsidiary or parent or to a
                    successor to our business as part of a merger or sale of substantially all of
                    our assets. You may not assign or transfer these Terms of Service. If any
                    provision of this Terms of Service is held to be unenforceable for any reason,
                    the remaining provisions will be unaffected and remain in full force and
                    effect.</span></p>

                <p><span>14.2<span>&nbsp;&nbsp;
                </span></span><span>For any
                    questions or comments, or to report violations of this agreement, including
                    receipt of spam or unwanted communications from a User, contact us at </span><span>support@tiwit.in<span>
                        with “Terms of Service” in the subject line of your email.</span></span></p>

                <p ><b><span>15.<span>&nbsp;
                </span></span></b><b><u><span>ELECTRONIC
                    COMMUNICATIONS.</span></u></b></p>
                <p><span>15.1<span>&nbsp;&nbsp;
                </span></span><span>When you
                    use the Site or send emails to us, you are communicating with us
                    electronically. You consent to receive communications from us electronically. </span></p>
                <p><span>15.2<span>&nbsp;&nbsp;
                </span></span><span>We will
                    communicate with you by email or by posting notices through the Site. You agree
                    that all agreements, notices, disclosures and other communications that we
                    provide you electronically satisfy any legal requirement that such
                    communications be in writing.</span></p>
                <p><span>&nbsp;</span></p>
                <p><a name="_heading=h.30j0zll" /><span>This Agreement is deemed
                    accepted upon any use of any of the Site. The section titles in this Agreement
                    are for convenience only and have no legal or contractual effect.</span></p>
            </div>
        </Layout>
    )
}

export default Terms