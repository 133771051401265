import React from 'react'
import Layout from '../../components/Layout/Layout'
import styles from "./PrivacyPolicy.module.css"


const RefundPolicy = () => {
    return (
        <Layout headerType="opaque">
            <div className={styles.inner_banner}>
                <h2>Refund Policy</h2>
            </div>
            <div className={styles.pp_inner}>
                <p>
                    Tiwit Technology Solutions Private Limited (“TIWIT”) operates, manages, offers assistance and solutions to their participants/customers and makes available these services on its domain www.tiwit.life.
                </p>

                <p>
                    TIWIT understands the complex nature of service it offers on the platform www.tiwit.life to their participants/customers and in view of the sensitivities, complexities and significance of these services, the participants/customers acknowledge that they are availing these services on www.tiwit.life voluntarily at their own free will to equip themselves and to be better prepared.
                </p>

                <p>
                    In order to serve the interest of the participants/customers, Tiwit has tied up with leading Genetic counselling experts. (Called as “Medical Professionals”) who come with vast experience in the domain of Genome mapping and have immense contribution in their respective fields.
                </p>

                <p>
                    These Medical Professionals are domiciled in different jurisdictions ranging from United States of America, European Union, United Kingdom, Australasia and in addition, there are excellent Medical Professionals associated with leading hospitals in Indian Cities, who team up with TIWIT and congregate together as per the requirements of the web-related services, webinars or live online shows or any other social media interaction hosted by TIWIT on its platform www.tiwit.life
                </p>

                <p>
                    With  www.tiwit.life , TIWIT aims to bring in state of the art medical services and best in class services in the domain of genome mapping to their participants/customers and make it the best experience of their lives.
                </p>

                <p>
                    TIWIT does not foresee any of its clients backing off from the services voluntarily subscribed on the platform www.tiwit.life However, TIWIT  believes that there may be instances when a few participants/customers may opt  to cancel their booking. In view of the sensitivity on the one hand towards the participants/customers and professional commitments already made to Medical Professionals, on the other, TIWIT has made the refund policy as pointed out below.
                </p>
                <ul>
                    <li>
                        Refund will be processed only if the request for refund is submitted within 24 hours of purchase.
                    </li>
                    <li>
                        All the refund requests will be processed within 30 days of the receipt of refund request.
                    </li>
                    <li>
                        In the event the sample collection kit is already shipped by TIWIT, INR 3000/- shall be deducted from the refund amount only if the Customer informs TIWIT within 30 days period from the time he/she has received the Kit.
                    </li>
                    <li>
                        If the refund request is received before the sample collection kit is sent by TIWIT, INR 1000/- shall be deducted from the refund amount.
                    </li>
                </ul>

                <p>Participants/Customers acknowledge that the services offered on www.tiwit.life  is a specialised service and unless there is a valid reason for cancellation, Tiwit or www.tiwit.life  will not entertain  refund requests of customers/participants. Change of mind is not a good enough reason to avail or apply for refund.</p>
            </div>
        </Layout>
    )
}

export default RefundPolicy