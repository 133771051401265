import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import styles from "./BlogDetail.module.css";
import BlogCard from "../../components/BlogCard/BlogCard";
import axios from "axios";
import blogs from "./../../mockdata/blogs.json";
import blogList from "./../../mockdata/blogList.json";

// const client = axios.create({
//     baseURL: "https://blog.tiwit.life/wp-json/wp/v2/posts/"
// });

const BlogDetail = () => {
  const { id } = useParams();
  const item = blogs.find((i) => id === i.pageLink);
  const [post, setPost] = useState(item);
  return (
    <Layout headerType="opaque">
      <div className={styles.blog_inner}>
        <div className={styles.blog_content}>
          <img src={`${post.image}`} />
          <h1>{post.title}</h1>
          {post.description.map((i) => {
            return <p>{i}</p>;
          })}
        </div>
        <aside className={styles.blog_related}>
          <h2>Similar Blogs</h2>
          {blogList.featuredArticles.map((bl) => {
            return (
              <div className={styles.blog_small_card}>
                <img src={`${bl.image}`} />
                <div className={styles.blog_small_content}>
                  <h4>{bl.title}</h4>
                  {/* <h6>Dec 25</h6> */}
                </div>
              </div>
            );
          })}
          {/* <div className={styles.blog_small_card}>
                        <img src={`${post.image}`} />
                        <div className={styles.blog_small_content}>
                            <h4>Newborn Baby brain development</h4>
                            <h6>Dec 25</h6>
                        </div>
                    </div>
                    <div className={styles.blog_small_card}>
                        <img src={`${post.image}`} />
                        <div className={styles.blog_small_content}>
                            <h4>Newborn Baby brain development</h4>
                            <h6>Dec 25</h6>
                        </div>
                    </div>
                    <div className={styles.blog_small_card}>
                        <img src={`${post.image}`} />
                        <div className={styles.blog_small_content}>
                            <h4>Newborn Baby brain development</h4>
                            <h6>Dec 25</h6>
                        </div>
                    </div> */}
        </aside>
      </div>
    </Layout>
  );
};

export default BlogDetail;
