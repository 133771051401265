import React from "react";
import Layout from "../../components/Layout/Layout";
import ProductsCard from "../../components/ProductsCard/ProductsCard";
import styles from "./GeneTesting.module.css";
import productData from "./../../mockdata/products.json";

const GeneTesting = () => {
  return (
    <Layout headerType="opaque">
      <div className={styles.inner_banner}>
        <div className={styles.inner_banner_text}>
          <h1>Preventive Genomics</h1>
          <p>
            Simple gene test to gain comprehensive <br /> knowledge on the
            future of your health and wellness.
          </p>
        </div>
      </div>
      <div className={styles.gene_test}>
        <div className={styles.content}>
          <p>
            Preventive Genomics serves as a gateway towards <br />
            predictive and preventive healthcare. It is a comprehensive approach
            to the overall health and well-being of individuals of any age. Gene
            testing plays a significant role in the personalisation of care and
            aids in optimal therapy planning.
          </p>
          <p>
            TIWIT offers a selection of gene tests that will help people gain
            access to the numerous possibilities of preventive and predictive
            healthcare.
          </p>
          <div className={styles.products_listing}>
            {productData.map((product) => {
              return (
                <ProductsCard
                  key={product.id}
                  name={product.name}
                  thumbnail={product.thumbnail}
                  brand={product.brand}
                  price={product.price}
                  oldPrice={product.oldPrice}
                  link={product.link}
                  purchaseLink={product.purchaseLink}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GeneTesting;
