import React, { useState } from 'react'
import styles from './Banner.module.css'

const Banner = (props) => {

  return (
    <div className={styles.banner}>
      <div className={styles.banner_content}>
        <h1>
          We create <br />healthy, happy humans
          <span>One-stop shop for advanced healthcare services.</span>
          <button onClick={props.ctaClickHandler} className={styles.banner_btn}>Get Started</button>
        </h1>
      </div>
    </div>
  )
}

export default Banner