import React, { useRef } from "react";
import Layout from "../../components/Layout/Layout";
import styles from "./CordBloodBanking.module.css";
import { Link, useParams } from "react-router-dom";
import useIntersectionObserver from './../../custom-hooks/useIntersectionObserver/useIntersectionObserver'


const CordBloodBanking = () => {
    const whatRef = useRef(null);
    const whyRef = useRef(null);
    const typesRef = useRef(null);

    const whatSectionIsIntersecting = useIntersectionObserver(whatRef, { threshold: 1 });
    const whySectionIsIntersecting = useIntersectionObserver(whyRef, { threshold: 1 });
    const typesSectionIsIntersecting = useIntersectionObserver(typesRef, { threshold: .5 });


    const selectWhatHandler = () => {
        whatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const selectWhyHandler = () => {
        whyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const typesWhyHandler = () => {
        typesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <Layout>
            <div className={styles.cbb}>
                <div className={styles.cbb_banner}>
                    <div className={styles.cbb_banner_inner}>
                        <h1>Cord blood Banking</h1>
                        <p>
                        Reliable services to preserve your baby's stem cells, <br /> a ready source
                         of countless lifesaving possibilities.
                        </p>
                    </div>
                </div>
                <div className={styles.cbb_inner}>
                    <aside>
                        <ul>
                            <li 
                            className={whatSectionIsIntersecting ? `${styles.select_what} ${styles.active}` : `${styles.select_what}`}
                            onClick={selectWhatHandler}
                            >
                                <Link>What is cord blood?</Link>{" "}
                            </li>
                            <li 
                            onClick={selectWhyHandler}
                            className={whySectionIsIntersecting ? `${styles.select_why} ${styles.active}` : `${styles.select_why}`}
                            >
                                <Link>Why?</Link>{" "}
                            </li>
                            <li 
                            onClick={typesWhyHandler}
                            className={typesSectionIsIntersecting ? `${styles.select_types} ${styles.active}` : `${styles.select_types}`}
                            >
                                <Link>Types of banking</Link>{" "}
                            </li>
                            <hr />
                        </ul>
                    </aside>
                    <div className={styles.cbb_content}>
                        <div ref={whatRef}>
                            <h3>What is cord blood? How is it useful?</h3>
                            <p>
                                Cord blood banking is the process of collecting and storing blood
                                from the umbilical cord and placenta after birth. This blood is
                                rich in stem cells, which are unspecialized cells that can develop
                                into any type of cell in the body. Stem cells have the potential
                                to treat many diseases and medical conditions, such as leukemia,
                                anemia, and some types of cancer. They can also be used for tissue
                                regeneration and repair. Cord blood banking is a safe,
                                non-invasive procedure that can provide a potential treatment
                                option for many diseases. Storing cord blood also ensures that it
                                will be available in the future if needed.
                            </p>
                            <h3>What is the process of cord blood banking?</h3>
                            <p>
                                The process of cord blood banking involves collecting and storing
                                the blood from the umbilical cord and placenta after birth. The
                                cord blood is usually collected within a few minutes of birth, and
                                the entire process is typically completed within 15 minutes. The
                                collected blood is then tested for quality and quantity and
                                cryopreserved in a special laboratory facility. It can be stored
                                for many years without any significant deterioration. When needed,
                                the cord blood is thawed and used for treatments such as stem cell
                                transplants, tissue regeneration, and repair.
                            </p>
                        </div>
                        <div ref={whyRef}>
                            <h3>Why is cord blood the best source of stem cells?</h3>
                            <p>
                                Stem cells can be extracted from multiple sources in the human
                                body. It can be found in a person's bone marrow and peripheral
                                blood. Cord blood is the best source of stem cells because it is
                                readily available, poses minimal risk to the donor, and is a rich
                                source of stem cells that have the potential to develop into any
                                type of cell in the body. It is also a less controversial source
                                of stem cells compared to other sources, such as embryonic stem
                                cells. Furthermore, cord blood is less likely to be rejected by
                                the recipient's immune system, making it an ideal source for
                                transplantation.
                            </p>
                        </div>
                        <div ref={typesRef}>
                            <h3>Types of cord blood banks</h3>
                            <p>Cord blood can be stored in</p>
                            <ul>
                                <li>Public cord blood banks </li>
                                <li>Private cord blood banks </li>
                                <li>Community cord blood banks</li>
                            </ul>
                            <p>
                                Public cord blood banks are non-profit organizations that collect
                                and store donated cord blood, which is then made available to
                                anyone who needs it. This type of banking allows for the
                                possibility of a stem cell transplant for individuals whose family
                                members do not have a compatible source of stem cells. It is also
                                a less controversial source of stem cells compared to other
                                sources such as embryonic stem cells.
                            </p>
                            <p>
                                Private cord blood banks are facilities that store cord blood for
                                the exclusive use of the donor and their family. This type of
                                banking typically involves a fee and allows the donor to have more
                                control over the use and distribution of the stem cells.
                                Furthermore, since the cord blood is stored for the exclusive use
                                of the donor and their family, it is less likely to be rejected by
                                the recipient's immune system, making it an ideal source for
                                transplantation.
                            </p>
                            <p>
                                Community cord blood bank helps one gain access to the cord blood
                                inventory in which they are one of the qualified and consented
                                donors. Although this type of banking is similar to private cord
                                blood banking, what makes it unique is that it helps people access
                                stem cells from a wider pool among the members of the community
                                rather than relying on the small options given by private banking.
                                This model offers the benefits of both public and private cord
                                blood banksCommunity cord blood bank helps one gain access to the
                                cord blood inventory in which they are one of the qualified and
                                consented donors. Although this type of banking is similar to
                                private cord blood banking, what makes it unique is that it helps
                                people access stem cells from a wider pool among the members of
                                the community rather than relying on the small options given by
                                private banking. This model offers the benefits of both public and
                                private cord blood banks
                            </p>
                        </div>
                    </div>
                    {/* <div className={styles.cbb_contact}>
                        <div className={styles.contact_form}>
                            <h4>Let's talk</h4>
                            <div className={styles.form_group}>
                                <input type="text" placeholder="Name" />
                            </div>
                            <div className={styles.form_group}>
                                <input type="text" placeholder="Mobile number" />
                            </div>
                            <div className={styles.form_group}>
                                <input type="text" placeholder="Emai Id" />
                            </div>
                            <button type="submit">Book a callback</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </Layout>
    );
};

export default CordBloodBanking;
