import React from 'react'
import styles from './TestimonialCard.module.css'

const TestimonialCard = (props) => {
    return (
        <div className={styles.single_testimonial}>
            <div className={styles.single_testimonial__image}>
                <img src={`${props.UserImage}`} alt="user image" />

            </div>
            <div className={styles.single_testimonial__content}>
                <p>{props.UserContent}</p>
                <div className={styles.single_testimonial__footer}>
                <h4>{props.UserName}</h4>
                <div className={styles.testimonial_line}>
                    <div className={styles.testimonial_line_left}></div>
                    <div className={styles.testimonial_line_right}></div>
                </div>
                </div>
            </div>

        </div>
    )
}

export default TestimonialCard