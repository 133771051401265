import React from 'react'
import Layout from '../../components/Layout/Layout'
import styles from "./PrivacyPolicy.module.css"

const PrivacyPolicy = () => {
    return (

        <Layout headerType="opaque">
            <div>
                <div className={styles.inner_banner}>
                    <h2>Privacy Policy</h2>
                </div>
                <div className={styles.pp_inner}>

                    <p ><b><span >1.</span></b><b><span >INTRODUCTION </span></b></p>

                    <p><span>1.1<span >
                    </span></span><span>Tiwit Technology
                        Solutions Private Limited ("TIWIT") operates, manages, offers assistance and
                        solutions to their consumers by availing the services of prominent medical
                        professionals (defined in refund policy ) and makes available these services on
                        its domain http://www.tiwit.life/ (the "SITE") </span></p>
                    <p ><span >1.2<span >
                    </span></span><span >TIWIT and
                        the Site respect the sensitive nature of any personal information you provide
                        to us.&nbsp;&nbsp;We have created this Privacy Policy in order to demonstrate
                        our commitment to the privacy of those who visit our website http://www.tiwit.life/
                        &nbsp;</span></p>

                    <p ><span >1.3<span >
                    </span></span><span >This
                        Privacy Policy describes our online information collection, storage and
                        disclosure practices and the choices you have regarding how your personal
                        information is collected and used by us and by our service
                        providers.&nbsp;&nbsp;By accessing and using the Site, you consent to the collection,
                        use and disclosure of information through the Site as described in this Privacy
                        Policy.&nbsp;</span></p>

                    <p ><span >1.4<span >
                    </span></span><b><u><span >RIGHT
                        TO AMEND OR MODIFY THE PRIVACY STATEMENT:</span></u></b><span > We reserve the right to change this Privacy
                            Policy at any time.&nbsp;&nbsp;In the event we make changes to this Privacy
                            Policy, such policy will be re-posted in the "Privacy" section of our Site with
                            the date when such modifications were made indicating on the top of the page or
                            we may contact you to inform you about the revisions with a link to the revised
                            Privacy Policy.&nbsp;&nbsp;All changes are effective on the date listed at the
                            top of this page and will apply to all information that we have about
                            you.&nbsp;&nbsp;</span></p>

                    <p ><span >1.5<span >
                    </span></span><b><u><span >CONSENT:</span></u></b><span > Your continued use of the Site
                        or the services that we provide ("Services") after any change is posted
                        indicates your acceptance of the changes and your continued consent to our
                        processing of your personal data.&nbsp;&nbsp;If at any point you do not agree
                        to any portion of this Privacy Policy then in effect you should immediately
                        stop using the Site and the Services. Because this Privacy Policy contains
                        legal obligations we encourage you to review this Privacy Policy
                        carefully.&nbsp;&nbsp;</span></p>

                    <p ><span >1.6<span >
                    </span></span><b><u><span >Contact:</span></u></b><span > If you have any questions
                        concerning this Privacy Policy or our information collection, storage and
                        disclosure practices, please contact us at </span><span><a href="mailto:hello@happybirthing.in"><span style={{ color: '#1155CC' }}>support@tiwit.in</span></a></span><span >" or mail us at Tiwit Technology Solutions Private Limited,</span><span style={{ color: 'yellow' }}> </span><span >No. 133, 4th Floor, Janardhan
                            Towers, Residency Road, Bangalore 560 025 </span></p>
                    <p><b></b></p>
                    <p><span >2.</span><b><span >USE OF
                        Personal Information: </span></b><span >http://www.tiwit.life/ "collects personal data about you when you
                            visit the Site. &nbsp;"Personal Information" is information that may be used to
                            directly or indirectly identify you, including your name, email address,
                            physical address, phone number or other information about you. </span></p>

                    <p><span >2.1</span><b><span >Stage of Collection of Personal
                        Information:</span></b><span >
                            The majority of such Personal Information is collected when you register with
                            the Site, use our Services, subscribe to updates, sign up for our mailing list
                            or otherwise provide us with your contact information.&nbsp;&nbsp;Some of this
                            Personal Information will be required if you wish to partake in the Services
                            that we provide. &nbsp;</span></p>

                    <p><span >2.2</span><b><span >Type of Personal Information
                        Collected:</span></b><span > In
                            general, the type of Personal Information we collect includes, but is not
                            limited to:</span></p>

                    <p ><span >2.2.1</span><span >Personal
                        Information to contact you to promote our Services or alert you to features or
                        matters of interest;</span></p>
                    <p ><span >2.2.2</span><span >Personal
                        Information necessary to use the Services that you have requested, including
                        establishing an account;</span></p>
                    <p ><span >2.2.3</span><span >Unidentifiable
                        and aggregated Personal Information pertaining to your Site visits that help us
                        maintain the appropriate features, functionality and user experience</span></p>

                    <p><span >2.3</span><b><span >Ancillary use of Personal
                        Information:</span></b><span > http://www.tiwit.life/
                            or any third party" service providers on www.tiwit.life shall use that data to:
                        </span></p>
                    <p ><span >2.3.1</span><span >enable you
                        to log in to the Site; </span></p>
                    <p ><span >2.3.2</span><span >guard
                        against potential fraud; </span></p>
                    <p ><span >2.3.3</span><span >contact you
                        if there is a problem with your account; </span></p>
                    <p ><span >2.3.4</span><span >provide
                        answers to your inquiries or questions;" </span></p>
                    <p ><span >2.3.5</span><span >maintain
                        regular communication with you as may be necessary to inform you of offers;</span></p>
                    <p ><span >2.3.6</span><span >updates and
                        other information regarding http://www.tiwit.life/ and its Services.</span></p>

                    <p><span >3.</span><b><span >Social
                        Networks </span></b><span >If
                            you choose to connect to our Site through a third-party social network such as
                            Facebook, Twitter, Instagram and other networks (each, a "Social Network"), http://www.tiwit.life/
                            may collect Personal Information from your profile on such Social Network, such
                            as your name, user name, and email address. In addition, our Site offers
                            features that will allow you to share information from our Site to a Social
                            Network or share information from our social network sites. If you decide to
                            use such features, the Social Network may allow the sharing and collection of
                            information both to and from such Social Network. In these circumstances, you
                            are required to check the privacy policy of each Social Network before using
                            such features.</span></p>

                    <p><span >4.</span><b><span >Non-Identifiable
                        Data and Aggregated Personal Information: </span></b></p>

                    <p><span >4.1</span><span >Regardless of whether you
                        register for an account or submit information to us, http://www.tiwit.life/ and
                        third parties performing services for us may send one or more
                        "cookies" to your computer. &nbsp;Cookies are small text files placed
                        on your web browser when you visit our Site that store information on your
                        computer, such as your Site preferences. &nbsp;We use cookies when you sign in
                        to keep track of your personal session. &nbsp;We also use cookies to track your
                        activity on the Site as a unique person. &nbsp;For security purposes, all of
                        this information is stored in encrypted form. &nbsp;This data may be used to
                        display information about our Services on websites operated by third parties.</span></p>

                    <p><span >4.2</span><span >http://www.tiwit.life/ or any
                        of our service providers, including Google Analytics, may also collect web
                        surfing data related to your use of the Site. &nbsp;Such information may
                        include: your Internet Protocol (IP) address, browser type, and internet
                        service provider (ISP); your operating system; which of our web pages you
                        access and how frequently you access them; referral or exit pages; click stream
                        data; and the dates and times that you visit the Site. &nbsp;This data may be
                        collected using cookies, web beacons, page tags or similar tools. &nbsp;As with
                        cookies, the web surfing information is anonymous, "click stream"
                        transactional data that is not associated with any users as individuals.</span></p>

                    <p><span >4.3</span><span >Web surfing data and similar
                        information may be aggregated for administrative purposes. http://www.tiwit.life/
                        may, for example, use this aggregated information in the administration of the
                        Site to improve its usability and to evaluate the success of particular
                        marketing and advertising campaigns, search engine optimization strategies, and
                        other marketing activities. &nbsp;We also use it to help optimize the Site
                        based on the needs of our users.</span></p>

                    <p><span >5.</span><b><span >"TIME
                        AND MODE OF SHARING INFORMATION OTHER PARTIES</span></b></p>

                    <p><span >5.1</span><span >http://www.tiwit.life/ does not
                        sell or trade or license Personal Information about its users for marketing
                        purposes. &nbsp;We do, however, work with a number of trusted partners who
                        perform vital functions as part of our operations, including processing users"
                        postal codes to deliver any products to our customers. </span></p>

                    <p><span >5.2</span><span >We do not share your Personal
                        Information unless it is necessary to fulfil our responsibilities, including
                        providing information or services to you.</span></p>

                    <p><span >5.3</span><b><span >ADDITIONAL SHARING OF
                        INFORMATION: </span></b><span >We
                            contract with companies or individuals to provide certain services related to
                            the functionality and features of the Site, including content streaming, email,
                            messaging and hosting services, software development, client services, data
                            collection and management, and other administrative functions. We call them our
                            "Service Providers." We may share your information with our Service Providers
                            as appropriate for them to perform their services for us and our Service
                            Providers are permitted to use your Personal Information only for such purposes.
                            Our Service Providers are legally required to keep your Personal Information
                            private and secure.</span></p>

                    <p><span >5.4</span><span >We may share your Personal
                        Information with law enforcement or other government agencies as required by
                        law or for the purposes of limiting fraud. We reserve the right to disclose
                        your Personal Information when we believe that disclosure is necessary to
                        protect our rights or to comply with a judicial proceeding, court order or
                        legal process. </span></p>

                    <p><span >5.5</span><span >We further reserve the right to
                        disclose any of your Personal Information that we believe, in good faith,
                        appropriate or necessary to take precautions against liability, to investigate
                        and defend against any third-party claims or allegations, to assist government
                        enforcement agencies, to protect the security or integrity of the Site or our
                        services, or to protect the rights, property or personal safety of http://www.tiwit.life/
                        , its users, issuers, or others.</span></p>

                    <p><span >5.6</span><span >We may share your Personal
                        Information in connection with a corporate reorganization or transaction, such
                        as a divestiture, merger, consolidation, or asset sale, or in the unlikely
                        event of bankruptcy.</span></p>

                    <p><span >5.7</span><span >Notwithstanding any of the
                        above, we will not share your Personal Information if such sharing is
                        prohibited by applicable privacy and data protection law. </span></p>

                    <p><b><span >6.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span></b><b><u><span >COMMUNICATIONS
                        REGARDING OUR SERVICES</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><u><span ></span></u></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >We will send
                        you email notifications and free newsletters from time to time with suggestions
                        and other information. &nbsp;Some notifications are communications relating to
                        us and our Services. You may "opt-out" from receiving such communications by
                        following the "Unsubscribe" instructions provided in the email.</span></p>

                    <p><b><span >7.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span></b><b><u><span >LEGAL
                        OR SECURITY COMMUNICATIONS </span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}>  </p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >We also send
                        out notices that are required for legal or security purposes. &nbsp;For
                        example, certain notifications are sent for your own protection. &nbsp;In other
                        cases, these notifications involve changes to various legal agreements, such as
                        this Privacy Policy or the Terms of Service. &nbsp;Generally, you may not opt
                        out of such emails</span></p>

                    <p><b><span >8.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span></b><b><u><span >ACCOUNT
                        COMMUNICATIONS</span></u></b></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >We may also
                        send communications providing customer support or responses to questions
                        regarding the operation of the Services. Generally, you have the option to not
                        opt for such emails.</span></p>

                    <p><b><span >9.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span></b><b><u><span >DELETING
                        YOUR INFORMATION </span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}>  </p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >If you want
                        us to delete your Personal Information and your account, please contact us at support@tiwit.in
                        with your request. We will take steps to delete your information as soon as we
                        can, but some information may remain in archived/backup copies for our records
                        or as otherwise required by law.</span></p>

                    <p><span >10.</span><b><u><span >PERSONAL INFORMATION RETENTION</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}>  </p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >We retain
                        the Personal Information we receive as described in this Privacy Policy for as
                        long as you use our Services or as necessary to fulfil the purpose(s) for which
                        it was collected, provide our Services, resolve disputes, establish legal
                        defences, conduct audits, pursue legitimate business purposes, enforce our
                        agreements, and comply with applicable laws.</span></p>

                    <p><b><span >11.<span >&nbsp; </span></span></b><b><u><span >PAYMENT</span></u></b></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >http://www.tiwit.life/
                        uses Razorpay for processing payments." Neither http://www.tiwit.life/ nor
                        Razorpay stores your card data on their respective servers. The data is
                        encrypted through the PCI Security Standards Council (PCI SSC) "when processing
                        payment. </span></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >Your
                        purchase transaction data is only used as long as it is necessary to complete
                        the purchase transaction. After that is complete, your purchase the card
                        details will not be stored. Transaction information will be stored for any
                        future reference. </span></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >Our payment
                        gateway adheres to the standards set by PCI-SSC as managed by the PCI Security
                        Standards Council, which is a joint effort of institutions including Reserve
                        Bank of India, National Payment Corporation of India</span></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >PCI-SSC
                        requirements help ensure the secure handling of credit card information by our
                        store and its service providers. For more insight, you may also want to read
                        terms and conditions of razorpay on </span><span><a href="https://razorpay.com"><span style={{ color: '#1155CC' }}>https://razorpay.com</span></a></span></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}>  </p>
                    <p><span >12.</span><b><u><span >LINKS TO OTHER SITES</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><b></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >If you
                        follow any links that direct you away from the Site, including links to social
                        media sites, among others, this Privacy Policy will not apply to your activity
                        on the other sites you visit. We do not control the privacy policies or the
                        privacy practices of any third parties.&nbsp;</span></p>

                    <p><span >13.</span><b><u><span >PRIVACY IN SUBMITTED MATERIALS</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><b></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><a name="_heading=h.gjdgxs" /><span >If you submit any photos to us, including through a social media
                        platform, such as Instagram, such photos may be displayed on the Site,
                        including photos of yourself or other individuals.&nbsp;&nbsp;By submitting such
                        materials, you forego any privacy rights in such materials.&nbsp;&nbsp;Please
                        consult the&nbsp;</span><span><a href="https://www.astartingpoint.com/static/tos.html"><span style={{ color: 'black', textDecoration: 'none' }}> </span></a><a href="https://www.astartingpoint.com/static/tos.html"><span style={{ color: 'black', textDecoration: 'none' }}>Terms of Service</span></a><a href="https://www.astartingpoint.com/static/tos.html"><span>&nbsp;</span></a></span><span >for other conditions relating to submission of materials to http://www.tiwit.life/
                            through social media sites.</span></p>

                    <p><b><span >14.<span >&nbsp; </span></span></b><b><u><span >SECURITY POLICY</span></u></b></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >We take
                        commercially reasonable steps to protect our customers" Personal Information
                        against unauthorized disclosure or loss.&nbsp;&nbsp;However, no data
                        transmission over the Internet can be guaranteed to be 100% secure.&nbsp;&nbsp;Therefore,
                        while we strive to protect user information, we cannot ensure or warrant the
                        security of any information you transmit to us or from the Site.&nbsp;&nbsp;You
                        engage in such transmissions at your risk.</span></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >If you
                        believe your Personal Information is being improperly used by us or any third
                        party, please immediately notify us via email at&nbsp;</span><span><a href="mailto:hello@happybirthing.in"><span style={{ color: '#1155CC' }}>support@tiwit.in</span></a></span><span > </span></p>

                    <p><b><span >15.<span >&nbsp; </span></span></b><b><u><span >CHILDREN UNDER SIXTEEN</span></u></b></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >This Site is
                        restricted to the use of adults over the age of majority in their place of
                        residence.&nbsp;&nbsp;No portion of the Site is directed to children under the
                        age of 16.&nbsp;&nbsp;Consequently, we do not knowingly collect personal
                        identifying information from any person we know is a child under the age of 16.
                    </span></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >If we learn
                        that we have collected personal information from a child under age 16, we will
                        delete that information as quickly as possible. If you are a parent or guardian
                        of a child under 16 years of age and you believe your child has provided us
                        with Personal Information, please contact us&nbsp;at&nbsp;support@tiwit.in </span></p>

                    <p><span >16.</span><b><u><span >LINKS TO THIRD PARTY PLATFORMS</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><b>  </b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >This Site or
                        communications may contain links to third party websites, over which we
                        exercise no control, including in the form of embedded content, sponsored
                        content or co-branded content. Except as set forth in this Privacy Policy, we
                        do not share your Personal Data with those third parties and we are not
                        responsible for the privacy policies of any third party or their management of
                        your Personal Information. Because they may treat your Personal Information
                        differently than we do, we suggest you read the privacy policies on those third
                        party websites prior to submitting any Personal Information to such sites.</span></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >In addition,
                        you may be redirected to a third party platform for certain functions.&nbsp;<i>See&nbsp;</i>above
                        section regarding "Additional Sharing of Information."</span></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}>  </p>
                    <p><b><span >17.<span >&nbsp; </span></span></b><b><u><span >RIGHT FOR DISCLOSURE OF
                        INFORMATION</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><b>  </b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >Under
                        certain circumstances, we will not provide such information, including where
                        the disclosure creates a substantial, articulable and unreasonable risk to the
                        security of that Personal Information, customers" account with us, or the
                        security of our systems or networks. We also will not disclose
                        government-issued identification numbers, financial account numbers, any health
                        insurance or medical identification numbers, or account passwords and security
                        questions and answers.</span></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><b></b></p>
                    <p><span >18.</span><b><u><span >YOUR RIGHTS AND OBLIGATIONS</span></u></b><u></u></p>

                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >We ask that
                        you keep the Personal Information that you provide to us current and that you
                        correct any information you have provided us by contacting us at&nbsp;</span><span><a href="mailto:hello@happybirthing.in"><span style={{ color: '#1155CC' }}>support@tiwit.in</span></a></span><span > </span><u><span style={{ color: 'blue' }}>.</span></u><span >" You represent and warrant
                            that all Personal Information you provide us is true and correct and relates to
                            you and not to any other person.&nbsp;</span></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span ><br />
                        If you use the Site, you are responsible for maintaining the confidentiality of
                        your account and for restricting access to your computer or device, and you
                        agree to accept responsibility for all activities that occur under your
                        account.&nbsp;</span></p>

                    <p><b><span >19.<span >&nbsp; </span></span></b><b><u><span >GOVERNING LAW</span></u></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><b></b></p>
                    <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '18.15pt', marginLeft: '18.0pt', textAlign: 'justify', border: 'none' }}><span >This Privacy
                        Policy and the relationship between the Parties hereto shall be governed by,
                        and interpreted in accordance with, the laws of India. The Parties irrevocably
                        agree that the courts at Bangalore, Karnataka India shall have exclusive
                        jurisdiction in relation to all matters arising out of this Agreement.</span></p>
                    <p style={{ textIndent: '18.0pt', lineHeight: '150%' }}><b><span>PRIVACY COMPLIANCE OFFICER</span></b></p>
                    <p style={{ textIndent: '18.0pt', lineHeight: '150%' }}><b><span >TIWIT TECHNOLOGY
                        SOLUTIONS PRIVATE LIMITED</span></b></p></div>

            </div>
        </Layout>
    )
}

export default PrivacyPolicy