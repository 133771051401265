import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import styles from "./Partnership.module.css"
import Modal from 'react-modal';

const Partnership = () => {
    const [partnershipType, setPartnershipType] = useState("VENDOR")
    const [paramValue, setParamValue] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id === "vendors") {
            setPartnershipType("VENDOR")
        }
        if (id === "investors") {
            setPartnershipType("INVESTOR")
        }
        if (id === "sales") {
            setPartnershipType("SALES_PARTNER")
        }
    }, [id]);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            background: "transparent"
        },
        overlay: {
            position: 'fixed',
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            zIndex: 999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgb(0 0 0 / 75%)'
        },
    };

    return (
        <Layout>
            <div className={styles.partnerships}>
                <aside>
                    <h2 onClick={() => console.log(paramValue)}>
                        Together Let's <span>Create healthy, happy humans</span>
                    </h2>
                    <ul>
                        <li className={partnershipType === "VENDOR" ? `${styles.select_vendor} ${styles.active}` : `${styles.select_vendor}`}><Link to="/partnerships/vendors">I represent a vendor</Link>  </li>
                        <li className={partnershipType === "INVESTOR" ? `${styles.select_investor} ${styles.active}` : `${styles.select_investor}`}><Link to="/partnerships/investors">I am an investor</Link>  </li>
                        <li className={partnershipType === "SALES_PARTNER" ? `${styles.select_sales} ${styles.active}` : `${styles.select_sales}`}><Link to="/partnerships/sales">I want to be a sales partner</Link>  </li>
                        <hr />
                    </ul>
                </aside>
                {partnershipType === "VENDOR" && <div className={styles.partnerships_content}>
                    <h3>Tiwit for vendors</h3>
                    <p>TIWIT, India's first futuristic healthcare gateway, is inviting you to be a successful stakeholder in its journeys ahead. </p>
                    <p>We understand the importance of having access to reliable and affordable healthcare. This motivates us to strive to make our services accessible to everyone. Our commitment is to be a unicorn curator of the best regenerative therapies at affordable rates to provide life-changing technologies to everyone in need. </p>
                    <p>We are fast building a network of top-class healthcare providers to connect with India's awakening citizens because we believe in building a healthy, happy society. </p>
                    <p>TIWIT is also well-equipped informers and educators, and awareness-generators in the field of predictive, preventive and personalised medicine. Through innovative healthcare services and products, we help people make thoughtful decisions that help them quit unhealthy lifestyles, and also maintain positive health behaviors in a more effective way. Thus, we are giving people the benefit of a closer and deeper understanding of their health in the most accessible and convenient way.</p>
                    <p>We are fast becoming a prominent one-stop outlet for major vendors to sell groundbreaking technologies in the field of healthcare. Our years of research in this field have helped us gain comprehensive knowledge about the market which will undoubtedly help our vendors while selling through us. </p>
                    <h4>Our store is now open to accepting products & services in these segments:</h4>
                    <ul>
                        <li>Preventive Genomics
                        </li>
                        <li>Cord Blood Banks
                        </li>
                        <li>Wearables
                        </li>
                        <li>Mental Wellness
                        </li>
                    </ul>
                    <p>
                        Our priority is to build a strong presence bridging healthcare and technology. We specifically keep in mind evolving customer wants and urgent needs in the emerging health markets.
                    </p>
                    <h4>Our current strategy is aimed at:</h4>
                    <ul>
                        <li>Convinced conversions</li>
                        <li>Long-term customer loyalty</li>
                        <li>Stable market growth</li>
                        <li>A steady cash flow and of course</li>
                        <li>Building up a powerful brand presence</li>
                    </ul>
                    <p>Join and be a part of India's biggest health revolution to turn our lives healthier, smarter, and happier.
                    </p>
                    <p>Just pick up the phone and say 'hello' to us at
                        <strong> +91 890 423 1010</strong>.
                    </p>
                </div>}
                {partnershipType === "INVESTOR" && <div className={styles.partnerships_content}>
                    <h3>Tiwit for investors</h3>
                    <p>TIWIT wishes to transform a small portion of the world by promoting predictive and preventative healthcare solutions. </p>
                    <p>We are here to offer an easily accessible digital platform that presents ultramodern healthcare services and products backed by state-of-the-art technology to everyone in India. Our quest to promote innovative and effective healthcare systems in India has helped us develop a collaborative digital initiative with the world's best healthcare providers, research institutes, and universities. Our initial offerings include cord blood banking, genetic tests, mental wellness schemes, and healthcare wearables. </p>
                    <p>We aspire to be the most reliable name associated with health, happiness, vitality, and innovation. Our services are aimed to benefit people of any age, gender, and ethnicity. TIWIT intends to relieve people of their health concerns by providing a precise and personalised predictive and preventive diagnosis based on their health indices. </p>
                    <p>India's healthcare market holds unlimited growth potential for watchful investors. And TIWIT being an aggregator of innovative healthcare services and products, offers an exciting chance to invest in this fast-evolving market. </p>
                    <p>Join us today!</p>
                </div>}
                {partnershipType === "SALES_PARTNER" && <div className={styles.partnerships_content}>
                    <h3>Tiwit for sales partners</h3>
                    <p>TIWIT is on a mission to create happier people across the world. We are about to make a powerful change in the healthcare industry with the most innovative technologies that precisely align with the current world's needs.</p>
                    <p>TIWIT, through its digital platform, extends the most reliable predictive and preventive healthcare services that assist in transforming the way one can look after themselves.  We are building a futuristic healthcare infrastructure that has the ability to entirely transform the Indian healthcare sector, and we, as a team, would need additional forces to join us as we embark on this operation. Our stem cell banking services, gene test packages, wellness programs, and bio wearables are the initial set of services we've launched through our platform, and they have enormous potential and should be promoted across the country. We seek sales partners who have the potential to help us bridge the most ingenious technologies to the people who require them.</p>
                    <p>TIWIT is in search of responsible sales partners who would help us sell the most innovative healthcare services, penetrate these services into remote parts of the country, and foster a more cognizant and healthier generation. Join us and be the first to be a part of India’s biggest health revolution. Get ready to go above and beyond with TIWIT, India’s first futuristic healthcare platform!</p>
                    <p>Join us to help make a difference!</p>
                </div>}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Register form"
            >
                <div className={styles.contact_form}>
                    <h4>Let's talk</h4>
                    <div className={styles.form_group}>
                        <input type="text" placeholder='Name' />
                    </div>
                    <div className={styles.form_group}>
                        <input type="text" placeholder='Mobile number' />
                    </div>
                    <div className={styles.form_group}>
                        <input type="text" placeholder='Emai Id' />
                    </div>
                    <button type='submit'>Book a callback</button>
                </div>
            </Modal>
            <div className={styles.partnerships_footer}>
                <h3>If you think what we're doing make sense</h3>
                <button onClick={openModal}>Click to register</button>
            </div>
        </Layout >
    )
}

export default Partnership